<template>
  <v-app v-if="!loadingCompanyInSuite && companyInSuite">
    <v-container class="pa-0 ma-0">
      <v-overlay :value="true" opacity="1" z-index="200" color="primary">
        <v-card outlined color="primary darken-1" :min-width="460">
          <v-row v-if="loadingVerification || step === 5" justify="center">
            <v-col align="center">
              <v-progress-circular
                width="3"
                size="70"
                color="white"
                indeterminate
                class="mt-5 mb-4"
              >
                <Logo
                  product="conecta_control"
                  height="45"
                  white
                  class="blink"
                />
              </v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col align="center">
              <Logo
                product="conecta_control"
                width="200"
                full
                white
                class="mb-3"
              />
            </v-col>
          </v-row>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text align="center">
                <v-form @submit.prevent="" ref="phoneForm">
                  <p
                    class="text-h6 font-weight-regular mb-6"
                    style="white-space: nowrap; text-align: center"
                  >
                    {{ $ml.get("verification_not_a_robot") }}
                  </p>
                  <p
                    class="pt-2"
                    style="white-space: nowrap; text-align: center"
                  >
                    {{ $ml.get("verification_receive_code") }}
                  </p>
                  <v-row justify="center">
                    <v-col align="center" cols="12" sm="8" md="9">
                      <InternationalPhone
                        color="accent"
                        :label="$ml.get('phone')"
                        :hint="$ml.get('verification_phone_hint')"
                        :phone="phone"
                        :preferred-countries="['br']"
                        :rules="rulePhoneNumber"
                        outlined
                        @update="phone = $event"
                      />
                    </v-col>
                  </v-row>
                  <p class="font-weight-light" style="text-align: center">
                    {{ $ml.get("verification_we_will_verify") }}
                  </p>
                </v-form>
              </v-card-text>
              <v-divider />
              <v-card-actions class="pa-4">
                <v-spacer />
                <v-btn
                  color="accent"
                  class="text-none text-subtitle-1 font-weight-medium"
                  @click="validatePhoneToNextStep"
                >
                  {{ $ml.get("buttonNext") }}
                </v-btn>
              </v-card-actions>
            </v-window-item>

            <v-window-item :value="2">
              <VerificationRecaptcha
                :status="recaptchaStatus"
                @status-change="recaptchaStatus = $event"
                @verified="sendSMSAndHandleResponses"
                @error="handleCaptchaError"
              />
              <v-input
                :rules="captchaRules"
                :value="{ captchaErrorMessage }"
                style="width: 300px; margin: auto; text-align-last: center"
              />
              <v-card-actions class="pa-4">
                <v-spacer />
              </v-card-actions>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-text>
                <p
                  class="text-h6 font-weight-regular"
                  style="white-space: nowrap; text-align: center"
                >
                  {{ $ml.get("verification_insert_code") }}
                </p>
                <p
                  class="pt-1"
                  style="
                    max-width: 450px;
                    padding: 0px 40px;
                    text-align: center;
                  "
                >
                  {{ $ml.get("verification_code_sent") }}
                </p>
                <v-form @submit.prevent="" ref="codeForm" lazy-validation>
                  <v-otp-input
                    v-model="otp"
                    @finish="finish"
                    length="6"
                    type="number"
                    color="accent"
                    class="d-flex justify-center body-2"
                    style="width: 300px; margin: auto"
                  />
                  <v-input
                    :rules="otpRules"
                    :value="{ codeIsValid, otpErrorMessage, otp }"
                    style="width: 300px; margin: auto; text-align-last: center"
                  />
                </v-form>
              </v-card-text>
              <v-divider />
              <v-card-actions class="pa-4">
                <v-spacer />
                <v-card-subtitle
                  v-if="timerInterval"
                  class="d-flex justify-center"
                >
                  <span v-if="timer !== 0">
                    <span v-if="timerReason === 'resendCode'">
                      {{
                        $ml.with("timer", timer).get("verification_resend_in")
                      }}
                    </span>
                    <span v-else>
                      {{
                        $ml.with("timer", timer).get("verification_expire_in")
                      }}
                    </span>
                  </span>
                </v-card-subtitle>
                <v-divider
                  v-if="timerInterval && timer !== 0"
                  vertical
                  class="me-3"
                />
                <v-btn
                  v-if="changeOption === 'resendCode'"
                  text
                  @click="resendCode"
                  class="text-none text-subtitle-1 font-weight-medium"
                >
                  {{ $ml.get("verification_resend_code") }}
                </v-btn>
                <v-btn
                  v-if="changeOption === 'skipStep'"
                  color="accent"
                  :disabled="loadingSkip"
                  @click="saveNumberAndPass"
                  :loading="loadingSkip"
                  class="text-none text-subtitle-1 font-weight-medium me-2"
                >
                  {{ $ml.get("verification_continue_wo_code") }}
                </v-btn>
                <v-btn
                  v-else
                  color="accent"
                  :disabled="!confirmationResult || loadingVerification"
                  class="text-none text-subtitle-1 font-weight-medium"
                  @click="finish"
                >
                  {{ $ml.get("buttonNext") }}
                </v-btn>
              </v-card-actions>
            </v-window-item>

            <v-window-item :value="4">
              <v-card-text>
                <p
                  class="text-h6 font-weight-regular"
                  style="white-space: nowrap; text-align: center"
                >
                  {{ $ml.get("verification_went_wrong") }}
                </p>
                <p
                  class="pt-2 px-5 font-weight-light"
                  style="white-space: nowrap; text-align: center"
                >
                  {{ errorMessage }}
                </p>
              </v-card-text>
              <v-divider />
              <v-card-actions class="pa-4"> </v-card-actions>
            </v-window-item>

            <v-window-item :value="5">
              <v-card-text>
                <p
                  class="text-h6 font-weight-regular"
                  style="white-space: nowrap; text-align: center"
                >
                  {{ $ml.get("verification_concluded") }}
                </p>
                <p
                  class="pt-2 px-5 font-weight-light"
                  style="white-space: nowrap; text-align: center"
                >
                  {{ $ml.get("verification_redirect_you") }}
                </p>
              </v-card-text>
              <v-card-actions class="pa-4"></v-card-actions>
            </v-window-item>
          </v-window>
        </v-card>
        <v-menu offset-x left :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              dense
              class="text-body-2 mt-0"
              v-bind="attrs"
              v-on="on"
              style="width: 200px"
            >
              <v-list-item-icon class="mr-4 px-0">
                <v-icon v-text="'mdi-translate'" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">
                  {{ currentLanguage }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="ml-auto">
                <v-icon v-text="'mdi-chevron-down'" />
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in allLanguages"
              :key="index"
              @click="changeLanguage(item.lang)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-overlay>
    </v-container>
  </v-app>
  <PageLoading v-else />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  getAuth,
  useDeviceLanguage,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { removeMask } from "@/helpers/utils";
import PageLoading from "@/components/PageLoading.vue";
import VerificationRecaptcha from "@/components/verification/VerificationRecaptcha.vue";

export default {
  name: "PhoneVerificationFlow",
  components: {
    PageLoading,
    VerificationRecaptcha,
  },
  data: () => ({
    step: 1,
    codeIsValid: true,
    otp: null,
    phone: "",
    recaptchaStatus: "not-showing",
    confirmationResult: null,
    errorMessage: "",
    otpErrorMessage: "",
    captchaErrorMessage: "",
    timer: 15,
    changeOption: "",
    loadingCompanyInSuite: false,
    loadingSkip: false,
    loadingVerification: false,
    timerInterval: null,
    timerReason: "resendCode",
    retries: 0,
    invalidPhones: [],
  }),
  computed: {
    ...mapGetters([
      "token",
      "companyInSuite",
      "currentLanguage",
      "allLanguages",
    ]),

    rulePhoneNumber() {
      return [
        (v) =>
          !v.length ||
          /^[\d ()+-]+$/.test(v) ||
          this.$ml.get("invalid_phone_number"),
        (v) => v.length <= 20 || this.$ml.get("max_20_chars"),
        (v) => !!v || this.$ml.get("verification_type_your_phone"),
        (v) =>
          !this.invalidPhones.includes(v) ||
          this.$ml.get("invalid_phone_number"),
      ];
    },

    otpRules() {
      return [
        () => !!this.codeIsValid || this.$ml.get("verification_invalid_code"),
        () => !this.otpErrorMessage || this.otpErrorMessage,
        () =>
          (!!this.otp && this.otp.length == 6) ||
          this.$ml.get("verification_code_of_6"),
      ];
    },

    captchaRules() {
      return [() => !this.captchaErrorMessage || this.captchaErrorMessage];
    },
  },
  methods: {
    ...mapMutations(["setLanguage"]),

    ...mapActions(["getCompanyInSuite", "setLoading"]),

    changeLanguage(lang) {
      this.setLoading(2000);
      this.$ml.change(lang);
      this.setLanguage(lang);
    },

    async sendSMS(phoneNumber) {
      try {
        const auth = getAuth();
        useDeviceLanguage(auth);
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          window.recaptchaVerifier
        );
        return confirmationResult;
      } catch (error) {
        console.error(error);
        // auth/invalid-app-credential
        return { error };
      }
    },

    async confirmCode() {
      try {
        const response = await this.confirmationResult.confirm(this.otp);
        return response;
      } catch (error) {
        console.error(error);
        return { error };
      }
    },

    async updateVerifiedPhone(phoneNumber, status_code, message = "") {
      const url = `${process.env.VUE_APP_API_BASE_URL}/companies/company/verify`;
      const auth = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const verified_phone = "+" + removeMask("phone", phoneNumber);
      try {
        return await this.$axios.put(
          url,
          { verified_phone, status_code, message },
          auth
        );
      } catch (error) {
        // this.step = 4;
        // this.errorMessage = this.$ml.get("verification_was_not_possible");
        this.updateVerifiedPhone(
          this.phone,
          "UNKNOWN_ERROR",
          error && error.message
        );
        this.saveNumberAndPass();
        throw error;
      }
    },

    async updateCompany(phone, code) {
      await this.updateVerifiedPhone(phone, code);
      setTimeout(() => {
        this.tryToGetBackVerifiedPhone();
      }, 5000);
    },

    saveNumberAndPass() {
      this.loadingSkip = true;
      this.step = 5;
      const phone = this.phone || localStorage.getItem("phone");
      this.updateCompany(phone, "REALESED_FOR_TIME_EXPIRED")
        .then(() => localStorage.removeItem("phone"))
        .finally(() => (this.loadingSkip = false));
    },

    initializedTimer(timerReason = "resendCode", seconds = 0) {
      if (this.changeOption.length) return;
      this.timer = seconds || 15 + 15 * 2 ** this.retries;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
      this.timerReason = timerReason;
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
          this.changeOption = timerReason;
        }
      }, 1000);
    },

    async validatePhoneToNextStep() {
      if (!this.$refs.phoneForm.validate()) return;
      this.step = 2;
      localStorage.setItem("phone", String(this.phone));
    },

    handleCaptchaError(errorMessage) {
      this.captchaErrorMessage = this.$ml.get("verification_unknown_error");
      this.updateVerifiedPhone(this.phone, "UNKNOWN_ERROR", errorMessage);
      this.saveNumberAndPass();
    },

    async sendSMSAndHandleResponses() {
      const result = await this.sendSMS(this.phone);
      if (result && result.verificationId) {
        this.confirmationResult = result;
        this.updateVerifiedPhone(this.phone, "CODE_SENT");
        this.initializedTimer(this.retries === 1 ? "skipStep" : "resendCode");
        return (this.step = 3);
      }
      const error = result && result.error;
      if (error && error.message.includes("auth/too-many-requests")) {
        this.captchaErrorMessage = this.$ml.get(
          "verification_too_many_requests"
        );
        this.updateVerifiedPhone(this.phone, "TOO_MANY_REQUESTS");
        this.saveNumberAndPass();
      } else if (error && error.message.includes("auth/invalid-phone-number")) {
        this.invalidPhones.push(this.phone);
        this.$refs.phoneForm.validate();
        this.step = 1;
      } else {
        this.captchaErrorMessage = this.$ml.get("verification_unknown_error");
        this.updateVerifiedPhone(
          this.phone,
          "UNKNOWN_ERROR",
          error && error.message
        );
        this.saveNumberAndPass();
      }
    },

    resendCode() {
      this.retries++;
      this.changeOption = "";
      this.step = 2;
    },

    async finish() {
      this.$refs.codeForm.validate();
      if (!this.otp || (this.otp && this.otp.length != 6)) return;
      this.loadingVerification = true;
      const response = await this.confirmCode();
      const error = response && response.error;
      if (response && !error) {
        this.codeIsValid = true;
        this.step = 5;
        return this.updateCompany(this.phone, "SUCCESS");
      }
      if (error.message.includes("auth/invalid-verification-code")) {
        this.codeIsValid = false;
        this.updateVerifiedPhone(this.phone, "INVALID_CODE");
      } else if (error.message.includes("auth/code-expired")) {
        this.otpErrorMessage = this.$ml.get("verification_code_expired");
        this.updateVerifiedPhone(this.phone, "EXPIRED_CODE");
        this.initializedTimer("resendCode");
      } else {
        this.otpErrorMessage = this.$ml.get("verification_unknown_error");
        this.updateVerifiedPhone(
          this.phone,
          "UNKNOWN_ERROR",
          error && error.message
        );
        this.initializedTimer("skipStep");
      }
      this.loadingVerification = false;
    },

    async tryToGetBackVerifiedPhone() {
      await this.getCompanyInSuite();
      setTimeout(() => {
        if (!this.companyInSuite.verified_phone) {
          setTimeout(this.tryToGetBackVerifiedPhone, 5000);
        }
      }, 5000);
    },
  },
  async beforeMount() {
    const auth = getAuth();
    if (auth.currentUser) {
      await signOut(auth);
    }
  },
  async created() {
    this.loadingCompanyInSuite = true;
    await this.getCompanyInSuite();
    this.loadingCompanyInSuite = false;
  },
};
</script>

<style>
.theme--dark.v-otp-input .v-input .v-input__control .v-input__slot {
  background: var(--v-primary) 80% !important;
}
</style>
