const general = {
  total: "Total",
  recommended: "Recomendado",
  attention: "Atenção",
  update_available: "Atualização disponível",
  settings: "Configurações",
  customize: "Personalizar",
  check_payment: `Para conferir as suas cobranças, acesse a sua <strong><a class="font-weight-bold" href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/" target="_blank"> Área do cliente</a></strong> no Superlogica.`,
  check_payment_mistake:
    "Se você já realizou o pagamento e acredita que tenha ocorrido algum engano, entre em contato com financeiro@conectasuite.com",
  language: "Idioma",
  theme: "Tema",
  google_admin: "Admin Google",
  coming_soon: "Em breve",
  try_new_sso: "Experimente o novo Control",
  schedule_meeting_button: "Conversar com a equipe",
  activation_evaluation_text: "Avaliar experiência com a ativação",
  successful_activation_title: "Tudo feito! Controle de acesso ativado",
  successful_activation_description_title_1:
    "Agora para acessar o Workspace os usuários devem seguir as regras configuradas em seus respectivos turnos",
  successful_activation_description_title_2:
    "Caso os usuários não tenham configurado uma senha, no próximo login os usuários devem utilizar a senha temporária para acessar",
  successful_activation_description_title_3:
    "Usuários sem turno não conseguirão acessar o Workspace",
  successful_activation_description_subtitle_3: `Esqueceu de alguém? Acesse <span class="font-weight-bold"><a href="/users">Usuários</a></span> para corrigir o problema.`,
  schedule_explanation:
    "Nossa equipe está a disposição para você retirar todas as suas dúvidas. Agende um horário e converse com nosso time de especialistas.",
  control_activation_schedule_title:
    "Ficou com dúvidas na ativação do Control?",
  illustration_by: "Ilustração por",
  illustrations_by: "Ilustrações por",
  seconds: "Segundos",
  second: "Segundo",
  tutorial_video: "Tutoriais em vídeo",
  and: "e",
  uppercase_send_email: "Enviar e-mail",
  lowercase_send_email: "enviar e-mail",
  successful_schedule_activation_title:
    "Ativação do controle de acesso agendada para",
  successful_schedule_activation_description_title_1:
    "Ativaremos o control automaticamente na data e hora informada acima",
  successful_schedule_activation_description_subtitle_1:
    "É possível cancelar o agendamento a qualquer momento",
  successful_schedule_activation_description_title_2:
    "Após a ativação, para acessar o Workspace os usuários devem seguir as regras configuradas em seus respectivos turnos",
  successful_schedule_activation_description_title_3:
    "Caso os usuários não tenham configurado uma senha, no próximo login após a ativação os usuários devem utilizar a senha temporária para acessar",
  successful_schedule_activation_description_title_4:
    "Usuários sem turno não conseguirão acessar o Workspace após a data de ativação",
  suspended_alert:
    "Prezado cliente, <br /> Seu acesso está temporariamente suspenso devido a um atraso no processamento do pagamento do seu plano.",
  service_soon_available: "O serviço estará disponível em poucos dias!",
  business_contact:
    "Nosso time comercial foi comunicado e em breve entrará em contato.",

  // TODO: REMOVER AVISOS DO SSO ANTIGO APÓS 04/03/24
  old_sso_title_message:
    "Notificação Importante: Há uma nova versão SSO disponível!",
  old_sso_title_message_2:
    "A versão atual do sistema de login único será desativada em 04/03/2024 e a mais recente entrará em vigor.",
  old_sso_sub_message:
    "Antecipe-se e configure agora a atualização do Conecta Control para desfrutar de um sistema de login mais eficiente e atual. Consulte a documentação e siga o passo a passo para uma transição suave.",
  permissions: "Permissões",

  temporary_access_alert_rules: "<b>Regras</b> do acesso temporário:",
  temporary_access_description:
    "O acesso temporário sobrepõe todas as regras de turno, permitindo o usuário a acessar sua conta de e-mail.",
  temporary_access_description2:
    "Ao definir um tempo de acesso, o usuário pode realizar o acesso a qualquer momento dentro do período válido.",
  temporary_access_description3:
    "Caso um tempo não seja definido, o usuário perderá o acesso temporário assim que ele realizar o log off.",
  notification: "Notificação",
  grant_single_access_description:
    "Liberando o acesso temporário sem definir um tempo, no primeiro logoff ele perderá o acesso concedido. Caso tenha um tempo definido, ele poderá realizar o login a qualquer momento dentro do tempo válido. </br> O tempo de acesso começa a contar a partir do próximo login dele.",
  remove_temporary_access_description:
    "Você está prestes a remover o acesso temporário para esse usuário. Ele não poderá realizar o login caso esteja fora das regras dos dos turnos.",
  remove_temporary_access:
    "Ao remover o acesso temporário este usuário será desconectado de todas as contas.",
  recomendation_on_remove_temporary_access:
    "Tem certeza que deseja remover o acesso temporário para este usuário?",
  force_user_logout:
    "Deseja forçar o log-out desse usuário ao remover o acesso temporário?",
};

const feedback = {
  rating_error: "Selecione um nível de satisfação para enviar a pesquisa",
  no_evaluation: "Sem avaliação",
  very_unsatisfied: "Muito insatisfeito",
  unsatisfied: "Insatisfeito",
  indifferent: "Indiferente",
  satisfied: "Satisfeito",
  very_satisfied: "Muito satisfeito",
  evaluate_your_experience: "Avalie sua experiência na",
  review_sent: "Avaliação enviada, obrigado!",
  leave_a_comment: "Deixe um comentário (Opcional)",
  successful_feedback_sent: "Tudo pronto, avaliação registrada!",
  edit_feedback: "Editar avaliação",
  feedback_error: "Erro ao registrar avaliação, por favor, tente novamente",
  rate_your_experience: "Avalie a sua experiência",
  rate_your_experience_subtitle:
    "Compartilhe conosco a sua experiência ao utilizar esta funcionalidade.",
  rate_app_on_marketplace: "Avalie o aplicativo no marketplace",
  common_did_not_work: "Não funcionou",
  common_confusing_interface: "Interface confusa",
  common_slow_performance: "Performance lenta",
  common_options_missing: "Faltam mais opções",
  group_add_or_remove_users_incorrect: "Usuário/grupo incorreto",
  group_add_or_remove_users_missing: "Usuário/grupo não encontrado",
  change_user_password_incorrect: "Senha incorreta",
  change_user_password_missing: "Faltam mais regras",
  change_user_password_usability: "Regras excessivas",
  customize_login_preview: "Pré-visualização do login diferente",
  control_activation_usability: "Usuários não bloqueados",
  control_activation_frequent: "Ativação inesperada",
  message_placeholder: "Porque...",
};

/* TELA DE LOGIN */
const login = {
  login: "Fazer login com o Google",
  msLogin: "Fazer login com a Microsoft",
  help: "Ajuda",
  login_description:
    "Esse aplicativo só está disponível para administradores corporativos Google Workspace, portanto, você deve fazer login com sua conta de administrador!",
  privacy: "Privacidade",
  terms: "Termos",
  init_session: "Faça o login para continuar",
  login_preview_info: "Esta é uma pré-visualização da sua página de login",
  agreement_label: "Criando uma conta, você concorda com nossos ",
  slogan: `<span class="login-text-spotlight">Controle</span> <br /> a <span class="login-text-spotlight">utilização</span> <br /> do <span class="login-text-spotlight">e-mail corporativo</span>`,
  scopes_help: "Entenda os escopos solicitados",
  try_for_free: "Experimente grátis",
  feature_list_label_1: "Restrição de acesso por horário, local e dispositivo",
  feature_list_label_2: "Logout de usuários após o horário",
  feature_list_label_3: "e muito mais!",
  installApp: "Instale o App no seu Google Workspace",
  retryWaitingGoogle: "Conectando ao Google. Por favor, aguarde um momento.",
  retryWaitingMicrosoft:
    "Conectando à Microsoft. Por favor, aguarde um momento.",
  retryJustInstalled:
    "Se você acabou de instalar o aplicativo, isso pode levar alguns minutos.",
  retryEnsureScopes:
    "Certifique-se de ter concedido as permissões necessárias.",
};

/* TELA DE AUTENTICAÇÃO */
const auth = {
  authenticating: "Autenticando",
  error_invalid_grant:
    "URL inválida. Por favor, verifique as permissões e tente novamente.",
  error_missing_code:
    "Ocorreu um erro ao tentar obter o token. Verifique as permissões e tente novamente.",
  error_no_domain_associated:
    "Não há nenhum domínio associado à essa conta. Por favor, entre com uma conta associada ao Workspace.",
  error_no_tenant_associated:
    "Não há nenhum locatário associado à essa conta. Por favor, entre com uma conta associada à Microsoft 365.",
  error_unregistered_user: "Usuário não encontrado em nossa base de dados.",
  error_marketplace_not_installed: `O aplicativo não está instalado no seu domínio. Favor instalar o app no <a href="https://workspace.google.com/marketplace/app/control/${process.env.VUE_APP_MARKETPLACE_APP_ID}" target="_blank">Google Workspace Marketplace</a>`,
  error_azure_not_installed: `O aplicativo não está instalado no seu locatário. Favor instalar o app tentando realizar o login e aceitando os escopos.`,
  error_invalid_google_access_token: `Token de acesso inválido. Alguma permissão de acesso está pendente. Para fazer o Login é preciso permitir acesso a todos os escopos no <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.`,
  error_invalid_microsoft_access_token: `Token de acesso inválido. Alguma permissão de acesso está pendente ou ainda está sendo processado pelo provedor.`,
  error_not_workspace_admin:
    "Você não é um Administrador ou não tem permissão para acessar o Conecta Control.",
  error_invalid_callback: "URL inválida. Por favor, tente novamente.",
  error_forbidden_customer:
    "Não é possível simular o login desse cliente. Cheque as permissões de Parceiro e tente novamente.",
  error_invalid_token:
    "Token inválido, por favor efetue o login e tente novamente.",
  invalid_token:
    "Token inválido, por favor efetue um novo login no Conecta Control e tente novamente.",
  error_login_required: "Por favor efetue o login e tente novamente.",
  error_missing_scope:
    "Para evitar problemas de funcionamento no Conecta Control, por favor, <b>permita os acessos</b> necessários no <b>Painel Workspace</b>.",
  error_missing_scope_alert:
    "Essa é uma resolução <strong>urgente</strong> e garante que todas as funcionalidades operem sem interupções.",
  error_missing_scope_support:
    "Em caso de dúvidas, contate nossa equipe de suporte pelos canais de atendimento.",
  error_missing_scope_for_non_admin:
    "Entre em contato com o seu administrador do Google Workspace para solicitar a autorização dos escopos necessários.",
  error_unknown: "Um erro desconhecido ocorreu. Por favor, tente novamente.",
  error_company_banned:
    "Acesso suspenso por algum critério técnico ou violação dos termos de uso.",
  marketplace_install:
    "O Conecta Control não está instalado no seu Google Workspace, instale agora para continuar.",

  error_suite_missing_scope:
    "Para ajustar o faturamento das licenças, por favor, <b>permita os acessos</b> necessários no <b>Portal Azure</b> para o Aplicativo <b>Conecta Suite 365</b>.",
  error_suite_missing_scope_alert:
    "Essa atualização serve para identificar contas compartilhadas e não considerá-las no faturamento.",
};

/* VERIFICAÇÃO DE TELEFONE */
const phoneVerification = {
  phone: "Telefone",
  verification_not_a_robot: "Confirme que você não é um robô",
  verification_receive_code:
    "Receba um código de verificação no seu smartphone",
  verification_phone_hint:
    "Insira um número de telefone com o DDI, DDD e número",
  verification_we_will_verify:
    "Nós verificaremos esse número por SMS (sujeito a cobranças).",
  verification_reload_in:
    "Ocorreu algum problema. A página será reiniciada em {timer} segundos...",
  verification_resolve_captcha: 'Resolva o "Captcha"',
  verification_after_resolving:
    "Após resolvê-lo, você receberá um SMS com um código de 6 dígitos para inserir abaixo",
  verification_insert_code: "Insira o código",
  verification_code_sent:
    "Foi enviada para seu número de telefone uma mensagem SMS contendo um código de 6 dígitos. Digite-o abaixo.",
  verification_resend_in:
    "Você poderá enviar um novo código em {timer} segundos...",
  verification_expire_in: "O código expira em {timer} segundos...",
  verification_resend_code: "Reenviar código",
  verification_continue_wo_code: "Continuar sem código",
  verification_went_wrong: "Algo deu errado",
  verification_concluded: "Validação concluída",
  verification_redirect_you: "Você será redirecionado para a aplicação.",
  verification_type_your_phone: "Digite um número de telefone",
  verification_invalid_code: "Código inválido.",
  verification_code_of_6: "O código deve conter 6 dígitos.",
  verification_was_not_possible:
    "Não foi possível enviar o código de verificação. Tente novamente.",
  verification_too_many_requests:
    "Você realizou muitas tentativas. Por favor, aguarde um pouco antes de tentar novamente.",
  verification_unknown_error: "Erro desconhecido. Por favor, tente novamente.",
  verification_code_expired: "Código expirado. Tente enviá-lo novamente.",
};

/* PÁGINA INICIAL */
const home = {
  homepage: "Página inicial",
  users_description: "Visualize a relação de usuários e atribua funções.",
  groups_description: "Crie, visualize e edite configurações dos turnos.",
  password_settings_description:
    "Solicite trocas de senhas e configure a senha temporária.",
  integrations_description:
    "Configure integrações com a VR Gente e Conecta Control AD.",
  customize_description:
    "Personalize a logo da sua empresa na página de login do Conecta Control",
  alerts_description:
    "Ver notificações sobre possíveis erros e relatórios de login.",
  conecta_suite_description:
    "Tenha acesso à novas configurações para conectar gestão a tecnologia.",
};

/* NAVBAR (BARRA SUPERIOR) */
const navbar = {
  global_search: "Pesquise usuários, turnos ou configurações",
  privacy_policy: "Política de privacidade",
  terms_of_use: "Termos de uso",
  active_dark_theme: "Ativar tema escuro (BETA)",
  active_light_theme: "Ativar tema claro",
  quick_settings: "Configurações rápidas",
  support: "Suporte",
  update: "Atualizar",
  new_service_plan: "Contratar plano ou serviço",
  go_to_self_checkout: "Conhecer os planos",
  sign_out: "Sair",
  //dislable control dialog
  disable_conecta_control_dialog_title: "Desativar o Conecta Control",
  disable_conecta_control_warning:
    "Esta alteração impacta todos os usuários da sua empresa",
  disable_conecta_control_description:
    "Ao desativar o Conecta Control a autenticação dos usuário volta a acontecer com a senha do Google.",
  disable_conecta_control_description2:
    "Será necessário recuperar a senha para os usuário que já não possuam mais a senha do Google.",
  solve_problem: "Resolva o problema: ",
  challenge_input_hint: "Insira o valor correto e clique em verificar",
  challenge_input_hint_validation: "Insira o valor correto",
  confirm_deactivate_message:
    "Para confirmar a desativação do controle de acesso com o Conecta Control, resolva o problema e em seguida clique em verificar.",
  frequently_questions: "Perguntas frequentes",
  chat_support: "Suporte via chat",
  receive_support_label: "Fale com nossos atendentes pelo chat",
  need_help_question: "Precisando de ajuda?",
  need_help_label: `Selecione o menu de suporte na barra superior e em seguida em 'Receber suporte' para falar diretamente com a nossa equipe.`,
  send_feedback: "Enviar sugestão",
  new: "Novo",
  schedule_meeting: "Agendar reunião",
};

/* SIDEBAR (BARRA LATERAL) */
const sidebar = {
  home: "Início",
  users: "Usuários",
  company_profile: "Perfil da empresa",
  groups: "Turnos",
  password_settings: "Configurações de senha",
  integrations: "Integrações",
  alerts: "Alertas",
  button_feedback: "Enviar feedback",
  customize_login: "Personalizar tela de login",
};

/* PRODUTOS E ITENS QUE ENVOLVAM A CONECTA */
const conecta = {
  conecta_suite: "Conecta Suite",
  conecta_track: "Conecta Track",
  conecta_sign: "Conecta Sign",
  conecta_control: "Conecta Control",
  conecta_control_ad_sync: "Conecta Control AD",
  integracao_ponto_mais: "Conecta Control & VR Gente",
  another_products: "Outros produtos Conecta",
  account_manager: "Gerenciar sua conta em Conecta Suite",
  visible_conecta_only: "Visível apenas para funcionários da Conecta",
  conecta_partners_simulation: "Modo Parceiro - Você está gerenciando ",
};

/* USUÁRIOS */
const users = {
  synchronizingUsers: "A sincronização de usuários está em andamento",
  syncUsers: "Sincronizando usuários",
  system: "Sistema",
  delete_user: "Deletou usuário",
  update_user: "Atualizou usuário",
  add_new_user: "Adicionou novo usuário",
  user_activate_sso: " ativou o Conecta Control",
  user_disable_sso: " desativou o Conecta Control",
  user_activate_sso_event: "Ativou Control",
  user_disable_sso_event: "Desativou Control",
  receive_user_single_access: "Recebeu acesso temporário",
  removed_single_access_from: "Removeu acesso temporário",
  granted_single_access: "liberou acesso temporário",
  forIndeterminateTime: "por tempo indeterminado",
  user_removed_group_rules: "Removeu regras de horário do turno",
  user_added_group_rules: "Adicionou regras de horário ao turno",
  user_updated_group_rules: "Editou as regras do turno",
  user_deleted_groups: "Excluiu os turnos",
  for: "por",
  to: "para",
  lost_user_single_access: "removeu acesso temporário de",
  grantedSingleAccess: "Concedeu acesso temporário",
  active_users: "Usuários ativos",
  inactive_users: "Usuários inativos",
  participant_of: "Participante de",
  user: "Usuário",
  search_users: "Pesquise usuários",
  show_all_users: "Mostrando todos os usuários",
  users_selected: "usuários selecionados",
  last_login: "Último login",
  without_group: "Usuários sem turno",
  is_on_vacation: "Usuários em férias",
  users_without_group_alert:
    "Os {count} usuários abaixo estão sem turno atribuído",
  without_group_alert: "Alguns usuários estão sem turno atribuído",
  without_group_alert2: "atribua pelo menos um turno a eles",
  confirm_delete_user_from_group_label:
    "Deseja confirmar a remoção deste usuário do turno ",
  confirm_delete_users_from_group_label:
    "Deseja confirmar a remoção dos usuários selecionados do turno ",
  you_are_editing_this_user_group_membership:
    "Você está editando uma participação em turnos do usuário.",
  manage_user_shifts_label: "Gerencie os turnos que este usuário participa.",
  vacation_period: "Período de férias",
  scheduled_vacation: "Férias programadas",
  on_vacation: "Está de férias",
  vacation_title: "Configurar modo férias",
  vacation_subtitle: "Selecione o período de férias para o bloqueio de sessão",
  vacation_description:
    "O modo férias bloqueia usuários de acessarem suas contas por um determinado período.",
  set_user_vacation: "Atribuir modo férias",
  edit_vacation_period: "Editar período de férias",
  remove_user_vacation: "Remover modo férias",
  set_users_vacation: "Atribuir modo férias para usuários",
  deny_user_access_in_vacation:
    "Bloquear acesso dos usuários durante as férias",
  deny_user_access_in_vacation_description:
    "Escolha quais usuários você deseja bloquear no período de férias e aplique o bloqueio de acesso ao Workspace para períodos correntes ou no futuro.",
  remove_users_vacation: "Remover modo férias dos usuários",
  request_single_access_description:
    "Insira seu endereço de e-mail e descreva para os administradores o motivo da solicitação.",
  request_password_change_description:
    "Insira seu endereço de e-mail e comente sobre sua solicitação de troca de senha com seus administradores.",
  login_history: "Histórico de login",
  history: "Histórico",
  title_deny_browser_access_in_conecta_control:
    "Escolha quais navegadores você deseja liberar acesso de forma rápida e simples!",
  description_deny_browser_access_in_conecta_control:
    "Selecione um turno específico de sua preferência e adicione os navegadores que você deseja liberar acesso! Rápido e muito prático!",
  changed_the_password_of: "alterou a senha de",
  change_own_password: "alterou a própria senha",
  apply_vacation_message_title:
    "Aplique uma mensagem de férias aos seus colaboradores.",
  apply_vacation_message_subtitle:
    "Configure uma resposta automática de férias para avisar que o colaborador não poderá responder aos e-mails recebidos durante o período de recesso determinado.",
  apply_vacation_question: "Deseja aplicar mensagem de férias?",
  go_to_sign: "Sim, ir para o Conecta Sign",
  min_len_is_10: "Deve conter no mínimo 10 caracteres",
  user_on_vacation_in_pontomais: "Usuário está em férias na VR Gente",
  all_users_selected: "Todos os usuários da tabela foram selecionados",
  create_users: "Criar usuários",
  control_only_password:
    "Essa senha funciona somente para fazer login no Control",
  current_session_state: "Estado atual da sessão",
  org_units: "Unidades organizacionais",
  hasNoValuesToShow: "Não há valores para exibir",
  customizeColumns: "Personalizar coluna",
  moveColumnUp: "Mover coluna para cima",
  moveColumnDown: "Mover coluna para baixo",
  user_added_manager_permission: "Adicionou gerente",
  user_removed_manager_permission: "Removeu gerente",
  has_been_added_admin: "foi adicionado como gerente",
  is_no_longer_an_administrator: "deixou de ser gerente",
};

const groups = {
  search_group: "Pesquisar turno",
  show_all_groups: "Mostrando todos os turnos",
  show_all_participants: "Mostrando todos os participantes",
  participants_selected: "participantes selecionados",
  add_group: "Adicionar turno",
  add_participants: "Adicionar participantes",
  add_participants_label:
    "Adicione novos participantes para este turno inserindo seus endereços de e-mail.",
  add_all_users_label:
    "Você pode adicionar todos os usuários da organização de uma única vez neste turno.",
  add_all_users_confirm_action: "Adicionar todos os usuários atuais para ",
  edit_participants: "Editar participantes",
  groups_selected: "turnos selecionados",
  manage_participants: "Gerenciar participantes",
  edit_password_policy: "Editar política de senha",
  edit_operation_hours: "Configurar horários de operação",
  edit_login_logout_access: "Configurar início e encerramento de sessão",
  pontomais_login_rules: "Regras de integração VR Gente",
  login_rules: "Regra de inicio de sessão",
  logout_rules: "Regra de encerramento de sessão",
  clocking_session_control_title: "Configurações de provedor de ponto",
  clock_selector_title: "Escolha o seu provedor de ponto",
  clock_provider_label: "Provedor de ponto",
  pontomais_login_message: "Permitir login só após bater o ponto",
  pontomais_auto_logout_info:
    "O logout pode levar até 5 minutos para ocorrer após o registro de ponto",
  pontomais_auto_logout_label:
    "Deslogar usuários de todos os dispositivos após bater o ponto...",
  pontomais_end_auto_logout_label: "Após bater o ponto de saída",
  pontomais_break_auto_logout_label: "Após bater o ponto de intervalo",
  pontomais_login_description:
    "Defina se os usuários somente podem iniciar sessão após bater o ponto",
  pontomais_auto_logout_message:
    "Deslogue os usuários de todos os dispositivos conectados após bater o ponto de saída ou de intervalo.",
  pontomais_end_auto_logout_description:
    "O colaborador será deslogado após registrar o ponto ao final do expediente",
  pontomais_break_auto_logout_description:
    "Será deslogado após registrar o ponto de saída para o intervalo",
  pontomais_link_how_to_config_shift:
    "Como configurar turnos na VR Gente | PontoMais",
  advanced_logout_rule:
    "Deslogar usuários de todos os dispositivos após o horário",
  logout_rule: "Deslogar usuários após o horário",
  next_group_password_change: "Próxima troca de senha em ",
  there_are_no_settings: "Sem configurações",
  always_active: "Sempre ativo",
  always_inactive: "Sempre inativo",
  scheduled_activation: "Programado para ativar",
  group_info: "Informações do grupo",
  delete_group_title: "Exclusão de turno",
  delete_group_label:
    "Todas as informações do turno inclusive regras de acesso serão excluídas. As contas dos participantes não serão excluídas.",
  activate_group_label:
    "Ative agora o turno para começar a restringir seus usuários de acordo com as regras definidas.",
  disabled_group_label:
    "Esta ação irá desativar o turno, e seus usuários não poderão mais acessar seguindo a regra desse turno. Desative apenas se você tiver certeza que os usuários já estão em outro turno.",
  delete_group_alert:
    "<b>Atenção!</b> Os usuários que estão participando do turno não estarão mais sujeitos às regras do mesmo. <br> <strong>Sugerimos realocar os usuários para outro turno.</strong>",
  clone_group_label: `<p>Essa ação irá clonar o turno e suas informações.</p>
    <p>Todas as configurações desse turno serão clonadas para um novo turno, tais como usuários, acessos e horários.</p>`,
  logout_group:
    "Os participantes deste turno serão deslogados, deseja continuar?",
  the_group: "O turno",
  the_groups: "Os turnos",
  has_been_deleted: "foi excluído",
  groups_have_been_deleted: "foram excluídos",
  cannot_be_deleted: "não pode ser excluído",
  groups_cannot_be_deleted: "não podem ser excluídos",
  delete_selected_shifts: "Excluir os turnos selecionados",
  cant_delete_groups: "Os turnos devem estar desativados",
  cant_activate_groups: "Somente turnos ativados foram selecionados",
  cant_deactivate_groups: "Somente turnos desativados foram selecionados",
  was_cloned: "foi clonado",
  could_not_be_cloned: "não foi possível ser clonado",
  updated_password_at: "Última troca de senha",
  automatic_logout: "Logout automático",
  days_to_change_password: ["Troca a cada", "dias"],
  login_shifts: "Início de sessão",
  login_label:
    "Valida se o usuário está em horário de trabalho via software de controle de ponto.",
  session_termination: "Encerramento de sessão",
  session_termination_label: `Os participantes do turno são deslogados no horário de fim de uma "regra de acesso" (periodo) do turno.`,
  integration: "Controle de ponto",
  password_policy_info_shifts:
    "Define em qual intervalo de tempo os usuários deverão alterar suas senhas pessoais.",
  groups_disabled:
    "Os turnos desativados não aparecem nos horários de operação",
  /* Regras */
  rule: "Regra",
  access_rule: "Horário de acesso",
  add_rules: "Adicionar regras",
  add_new_rule: "Adicionar nova regra",
  remove_rule: "Remover regra",
  edit_rule: "Editar regra",
  update_selected_rules: "Editar regras selecionadas",
  remove_selected_rules: "Remover regras selecionadas",
  showing_all_rules: "Mostrando todas as regras",
  selected_rule: "regra selecionada",
  selected_rules: "regras selecionadas",
  /*  */
  login_logout_access: "Início e encerramento de sessão",
  time_settings: "Horários de operação",
  auto_logout_label: "Deslogue os usuários do navegador ao final do horário",
  advanced_logout_label:
    "Deslogue os usuários ao final do horário de todos os dispositivos conectados",
  add_group_dialog_description:
    "Tem certeza que quer adicionar os usuários neste turno?",
  remove_group_dialog_description:
    "Tem certeza que quer remover os usuários deste turno?",
  /* Ações */
  logout_groups_success: "turnos deslogados com sucesso!",
  logout_group_success:
    "Os participantes deste turno foram deslogados com sucesso!",
  logout_groups_failure: "Não foi possível deslogar esses turnos.",
  logout_group_failure:
    "Não foi possível efetuar o logout dos participantes deste turno.",
  force_change_pass_groups_success:
    "Solicitação de troca de senha realizada com sucesso!",
  force_change_pass_groups_failure:
    "Não foi possível solicitar a troca de senha!",
  participants_info:
    "Faça o gerenciamento dos participantes do turno incluindo ou excluindo usuários.",
  password_policy_info:
    "Adicione uma política de senha para este turno, dessa forma os usuários irão precisar alterar a senha de forma recorrente.",
  disable_group_info: "Desative e ative o turno quando quiser.",
  logout_settings_info:
    "Defina como o usuário pode iniciar a sessão e como será feito o encerramento.",
  clone: "Clonar",
  add_users_to_group: "Usuários adicionados ao turno",
  remove_users_from_group: "Usuários removidos do turno",
  add_groups_to_user: "Turnos adicionados ao usuário",
  remove_groups_from_user: "Turnos removidos do usuário",
  user_added_on_shifts: "foi adicionado aos turnos",
  user_removed_from_shifts: "foi removido dos turnos",
  add_vacation_to_user: "Aplicou período de férias para o usuário:",
  remove_vacation_from_user: "Removido Período de férias do usuário:",
  group_config: "Configuração do turno",
  general_config: "Configurações Gerais",
  participants: "Participantes",
  passwords: "Senhas",
  general_config_description:
    "Gerencie o nome, status do turno, periodo de ativação, etc",
  participants_description:
    "Configure os usuários que devem seguir as regras deste turno",
  passwords_description:
    "Gerencie regras de troca períodica de senha ou force a troca imediata de senha",
  login_logout_access_description:
    "Defina os processos que afetarão o login/logout do usuário",
  time_settings_description:
    "Configure os horários e dias disponíveis para o usuário realizar login",
  clocking_login_config: "Configurações de início de sessão por ponto",
  logout_config: "Configurações de encerramento da sessão",
  clocking_logout_config: "Configurações de encerramento da sessão",

  //mudar isso quando ip e datas especiais forem separados
  ip_address_info:
    "A restrição por IP permite que você libere acesso por local através de endereços IP e DNS.",
  time_settings_info:
    "Para inserir um período de permissão clique no calendário, para excluir um período clique em “Horários de operação“ para ir às configurações.",
  request_group_password_change:
    "Tem certeza que deseja solicitar a troca de senha para os participantes deste turno?",
  restriction_label_by_browser: "Navegadores permitidos",
  allowed_browsers_info:
    "A restrição por navegador permite que você libere o acesso apenas para os navegadores selecionados.",
  allowed_os_info:
    "A restrição por sistema operacional permite que você libere o acesso apenas para os sistemas selecionados.",
  allowed_browsers: "Navegadores permitidos",
  allowed_os: "Sistemas operacionais permitidos",
  restrict_operational_system: "Restringir sistema operacional",
  os: "sistema operacional",
  activation_period: "Período de ativação do turno",
  activation_period_label: "Selecione o período de ativação do turno",
  activation_period_description:
    "Escolha o intervalo de tempo em que o turno permanecerá ativo",
  activation_period_placeholder: "Período que o turno permanecerá ativo",
  optional: "opcional",
  disable_shift_message_in_period: `<strong>ATENÇÃO:</strong> esse turno tem um período de ativação definido. Ao confirmar, essa configuração será removida.`,
  edit_activation_period: "Editar período de ativação",
  remove_activation_period: "Remover período de ativação",
  activate_shift_message_in_period: `Esse turno tem um período de ativação definido, ao confirmar, essa configuração será removida.`,
  shift_message_in_period_in_bulk: `Um ou mais turnos tem o período de ativação definido, ao confirmar, essa configuração será removida.`,
  activate_shift: "Ativar turno",
  activate_shifts_successfully:
    "Os turnos selecionados foram ativados com sucesso",
  deactivate_shifts_successfully:
    "Os turnos selecionados foram desativados com sucesso",
  deactivate_shifts_error:
    "Ocorreu um erro ao desativar os turnos, tente novamente",
  activate_shifts_error: "Ocorreu um erro ao ativar os turnos, tente novamente",
};

const adminPermissions = {
  user_permissions: "Permissões de usuários",
  manage_permissions: "Gerenciar permissões",
  add_admin: "Adicionar administrador",
  remove_admin: "Remover administrador",
  add_admin_main_text:
    "Adicione a permissão de administrador no Conecta Control para um usuário.",
  add_admin_helper_text:
    "Essa configuração não interfere nas permissões do Google Admin.",
  remove_admin_main_text:
    "Para remover a permissão de administrador do Conecta Control deste usuário, clique no botão de confirmação.",
  admin_permissions_description:
    "Gerencie os administradores do painel Conecta Control",
};

/* TOUR */
const tour = {
  buttonSkip: "Pular tutorial",
  startTutorial: "Iniciar tutorial pela ferramenta",
  buttonPrevious: "Voltar",
  buttonNext: "Próximo",
  buttonStop: "Concluir",
  getting_start: "Primeiros passos",

  user_tour: "Gerenciar usuários",
  user_tour_description:
    "Visualize os usuários do workspace, suas rotinas e altere senhas.",
  company_profile_tour: "Atualizar perfil",
  company_profile_tour_description:
    "Visite o conecta suite e atualize os dados de perfil da sua empresa.",
  permissions_tour: "Permissões de usuários",
  permissions_tour_description:
    "Gerencie e atribua permissões para que outros usuários façam a gestão do painel do Conecta Control. As permissões são exclusivas para este painel.",
  group_tour: "Configurar um turno",
  group_tour_description:
    "Com os turnos, você pode criar regras personalizadas de acesso por data, horário e local.",
  password_tour: "Defina uma senha temporária",
  password_tour_decription:
    "Configure uma senha temporária para o primeiro acesso dos usuários ou para a redefinição de senha destes.",
  integrations_tour: "Configure integrações",
  integrations_tour_description:
    "Combine o Conecta Control com até mais duas ferramentas poderosas e turbine o controle de acesso.",
  suite_tour: "Conecta Suite",
  suite_tour_description:
    "Acesse o painel do Conecta Suite, simplifique a gestão do Google Workspace com configurações avançadas para os usuários, aplicações em massa de assinaturas de e-mail e mensagens de férias.",
  config_tour: "Habilitar controle de acesso para todos",
  config_tour_description:
    "Configure, personalize e habilite ou desabilite o Conecta Control como gerenciador de login dos seus usuários. Fique tranquilo(a)! Após a sua configuração, os usuários continuarão conectados até o horário de logout estabelecido pelo turno ao qual ele pertence.",
  activate_control_tour: "Ativação do Conecta Control",
  activate_control_tour_description:
    "Após as configurações, ative o Conecta Control. Os usuários continuarão conectados até o horário de logout estabelecido pelo turno ao qual ele pertence.",

  start_settings: "Inciar configuração",
  start_settings_after: "Não configurar por enquanto",
  firstStepTitle: "Controle de acesso ao Google Workspace",
  firstStepDescription:
    "Seja bem vindo, estamos felizes em tê-lo como mais novo usuário! Para começar a usurfruir de todos os benefícios do Conecta Control é preciso executar alguns passos para enfim ativar o seu produto",
  secondStepTitle: "Definindo turnos",
  secondStepDescription:
    "Decida <b>quando e onde</b> seus usuários podem acessar o Google Workspace criando um turno com regras personalizadas.",
  thirdStepTitle: "Adicione os usuários aos turnos",
  thirdStepDescription:
    "Inclua os usuários como membros de um turno para que eles passem a seguir as regras previamente estabelecidas",
  fourthStepTitle: "Ative o Conecta Control",
  fourthStepDescription:
    "Ao final do processo, basta ativar o aplicativo para que as regras aplicadas comecen a valer para os usuários. Vamos começar a configuração?",
  schedule_activation_logout_alert:
    "Os usuários não serão deslogados automaticamente após a ativação automática",
};

const welcome = {
  title: "Bem-vindo(a)!",
  title_login_preview: "Boas vindas",
  subtitle:
    "Em alguns instantes os seus usuários estarão sincronizados.<br>Confira o que você precisa saber para iniciar no Conecta Control:",
  step1title: "Controle o acesso ao Google Workspace",
  step1description:
    "O Conecta Control é como uma camada de segurança para o acesso aos produtos Google Workspace.",
  step2title: "Restrições de acesso com turnos",
  step2description:
    "Crie turnos e defina quem, quando e onde terá permitido o acesso às ferramentas do Google Workspace.",
  step3title: "Fique tranquilo(a)!",
  step3description:
    "O controle de acesso do Conecta Control só terá efeito após a sua configuração, vamos te guiar nesse processo.",
};

const activationSteps = {
  getStartedTitle: "Boas vindas à ativação do Conecta Control",

  beforeStartActivationProccess: "Antes de iniciar, você precisa saber",

  getStartedSteps: "Este é um processo de 3 etapas",

  getStartedActivationProccess:
    "Você pode pausar esse processo a qualquer momento",

  getStartedProgress: "Seu progresso não será perdido caso saia desta página",

  getStartedLogoutUsersTip:
    "Ao final do processo, você decidirá se os usuários permanecerão logados ou terão de realizar o login novamente",

  letsStart: "Vamos começar",

  skipStep: "Pular etapa",

  okContinue: "Tudo certo, continuar",

  whatDoYouWantToDo: "O que você deseja fazer agora?",

  redirectTitle: "Você será redirecionado em",

  dontWorryYourActvationProgressIsSaved:
    "Não se preocupe, seu progresso até aqui não será perdido",

  comeBackAnytimeByClickingInControlActivation: `Volte a qualquer momento clicando em "Voltar para ativação"`,

  activationStepOneTitle: "Escolha de Turnos",
  activationStepTwoTitle: "Definir Senha",
  activationStepThreeTitle: "Regras de login e Sessão",
  activationStepFourTitle: "Revisar e Ativar Control",

  activationSessionOneTitle:
    "Verifique os turnos dos seus usuários antes de continuar",

  userShiftSettings: "Editar turnos",

  shiftTip:
    "Com os turnos, <b>defina regras de acesso</b> que seus <b>participantes devem seguir para acessar</b> o Workspace",

  shiftMembersTip:
    "<b>Adicione os usuários a turnos</b> para que eles passem a <b>seguir</b> àquelas <b>regras</b>",

  nonShiftMembersTip:
    "<b>Usuários sem turno</b> definido <b>não irão conseguir realizar login</b> após ativação",

  shiftChanges:
    "<b>Ajuste</b> turnos, usuários participantes, regras de acesso e logout <b>quando desejar</b>, <b>sem</b> precisar <b>reativar</b>.",

  userOptionRedirectToShifts: "Quero criar/editar meus próprios turnos",

  userOptionRedirectToUsers:
    "Quero adicionar usuários aos turnos já existentes",

  activationSessionTwoTitle: "Definir senha temporária",

  selectChangePasswordStepTitle:
    "Como deseja configurar a senha de seus usuários?",

  selectChangePasswordStepSubtitle:
    "A senha do Conecta Control é independente da sua senha do Google. Aqui, você deve definir como os seus usuários irão receber essa senha de acesso.",

  selectChangePasswordStepSubtitle2:
    "* Não se preocupe, nenhuma mudança será aplicada até a ativação final.",

  defineTemporaryPassword: "Definir senha temporária",

  temporaryPasswordWarningCoWorkers:
    "Avise aos usuários sobre o <b>primeiro acesso com a senha temporária</b>.",

  temporaryPasswordUserChangePassword:
    "Usuários novos no Control, <b>poderão escolher uma nova senha</b> em seu primeiro login.",

  temporaryPasswordFutureChanges: `A <b>senha</b> temporária <b>pode ser alterada ou consultada</b> a qualquer momento no menu <b>"Configurações de senha"</b>.`,

  temporaryPasswordChanged: "Senha temporária configurada",

  sendEmailToChangePassword: "Enviar e-mail de troca de senha",

  tipToAdminAboutThePasswords:
    "As <b>senhas aleatórias estão sendo enviadas por e-mail no momento.</b> Os usuários poderão mudar após realizar o login.",

  tipToAdminAboutChangePasswordLink:
    "Os <b>links estão sendo enviados por e-mail no momento.</b> Os usuários poderão trocar a senha através do link.",

  informUsersAboutTheNewPassword:
    "Informe aos colaboradores sobre a senha a seguir:",

  informUsersAboutRandomPassword:
    "As senhas temporárias serão enviadas individualmente para o e-mail principal de cada usuário.",

  informUsersAboutEmailToChangePassword:
    "Os e-mails serão enviados individualmente para o e-mail principal de cada usuário.",

  informAdminAboutTheRandomPasswords:
    "Não se preocupe, <b>as senhas irão ser enviadas por e-mail</b> após <b>concluir esta etapa</b>.",

  informAdminAboutChangedPassword:
    "Ao abrir o link, <b>os usuários deverão realizar o login com o Google</b> para poderem acessar a <b>troca de senha</b>.",

  informAdminAboutActivationWithEmail:
    "<b>É recomendável agendar a ativação ou aguardar</b> até que todos os usuários tenham alterado suas senhas.",

  whatDoYouWantToDoWithLoggedUsers:
    "O que você deseja fazer com o usuários que já estão logados no momento?",

  activationUsersLogoutHelp: `Se preferir, <b>você pode deslogar os usuários a qualquer momento</b> via página de "Usuários" ou deslogar por turno na página "Turnos"`,

  redirectToForceLogoutTip:
    "Após a ativação, selecione os usuários que deverão ser deslogados",

  forceLogoutToAllUsersTip:
    "Todos os usuários logados no momento serão deslogados",

  dontForceLogoutTip:
    "Usuários que estão logados neste momento não terão suas sessões encerradas",

  setUsersLogout: "Configurar logout dos usuários",

  activatingControlAccess: "Ativando controle de acesso..",

  activatingControlAccessTip:
    "Enquanto configuramos o Control, avise aos colaboradores sobre o primeiro acesso com a senha a seguir:",

  control_activation: "Ativação do Conecta Control",
  stepSectionTitle: "Vamos configurar o Conecta Control",
  stepSectionSubtitle:
    "Em mais algumas etapas você irá ativar o controle de acesso",
  activationStep1Description: `<p>Primeiramente é necessário configurar e organizar os turnos com os usuários e os horários em que eles podem acessar sua conta Google, caso você ainda não tenha feito isso ainda, clique em "Configurar"</p>
  <p>Lembrando que esse etapa é imprescindível para o funcionamento da ferramenta!</p>
  <p>Para ter acesso aos produtos do Google Workspace o usuário precisa fazer parte de algum turno. Prossiga apenas se você quer restringir completamente o acesso desses usuários.</p>
  <p>Agora estamos preparados para dar sequência na ativação do Conecta Control.</p>
  <p>O próximo passo é <b>configurar a senha padrão</b> da ferramenta, vamos lá?</p>`,
  activationStep2Description: `<p>Você chegou aqui, que ótimo, agora vamos para a configuração da senha padrão, prometo que vai ser rápido.</p>
    <p>A senha padrão é de <b>grande importância na ativação e no uso do Conecta Control</b>, mas por quê?</p>
    <p>Essa será a primeira senha que seu colaborador irá acessar o Conecta Control, depois desse primeiro acesso o colaborador vai criar uma nova senha de acesso.</p>
    <p>Agora que você já criou a senha padrão o próximo passo é avisar os colaboradores sobre essa mudança, vamos?</p>`,
  activationStep3Title: "Aviso aos colaboradores",
  activationStep3Description: `<p>Sim essa parte também é muito importante! Estamos quase prontos para finalizar o processo de ativação do Conecta Control<p>
    <p>Só falta avisar os colaboradores sobre essa mudança.</p>
    <p>Como você pode visualizar já estamos deixando a mensagem pronta para esse aviso, você só precisa copiar e enviar por e-mail.</p>
    <p>Fácil né, após enviar a mensagem espere de 1 a 3 dias para que todos tenham olhado a mensagem, recomendamos também que você envie esse aviso por WhatsApp :)</p>
  `,
  warning_control_activation_message_first: `<p>Prezado colaborador,</p>
    <p>Após o dia <span style="color:red">[inserir aqui o dia]</span> ao ser solicitado o usuário e senha no Google Workspace (G Suite), você irá acessar com a seguinte senha temporária:</p>`,
  warning_control_activation_message_second: `<p><b>Atenção:</b> Anote em algum lugar para não esquecer, pois após o dia <span style="color:red">[dia da ativação]</span> você não irá conseguir acessar o e-mail com a sua senha atual!
    <p>Após o primeiro acesso com a senha temporária, você deverá criar uma nova senha personalizada. Pode ser a mesma que você já está acostumado.</p>
    <p>Logo depois, você poderá utilizar as ferramentas do Google Workspace (G Suite) normalmente.</p>
    <p>Qualquer dúvida, fico à disposição!</p>
    <p>Abraços,<p>
    <p><span style="color:red">[seu nome]</span></p>`,
  uncompleted_steps: `<p>Algumas etapas parecem não ter sido concluídas e podem impactar na ativação do Conecta Control. Deseja continuar mesmo assim?</p>
    <p>Para concluir uma etapa, clique sobre o número que a representa.</p>`,
  go_to_users: "Ir para Usuários",
  go_to_groups: "Ir para Turnos",
  notify_users_via_email: "Notificar usuários por e-mail",
  notify_users_via_email_description:
    "Todos os usuários da empresa receberão um e-mail com instruções sobre o primeiro acesso utilizando a senha temporária informada. Deseja continuar?",
  generateRandomPasswords: "Gerar senhas temporárias únicas",
  generate_link_to_change_passwords: "Enviar um link de troca de senha",
  tipAboutExclusivePassword:
    "Uma senha exclusiva será criada e enviada para cada usuário por e-mail.",
  tip_about_send_link_to_change_password:
    "Os usuários receberão um e-mail para criar uma nova senha.",
  tip_about_define_temporary_password:
    "Os usuários usarão essa senha ao fazer o primeiro login.",
  schedule_activation_description:
    "Programe a ativação automática do Conecta Control escolhendo a data e hora desejadas",
  activation_date_hint: "Escolha uma data entre hoje e os próximos 14 dias",
  activation_date_title: "Agendar ativação do Conecta Control",
  schedule_activation: "Agendar ativação",
  activate_later: "Ativar depois",
  activate_later_description: "Sair e ativar manualmente depois",
  activation_domain: "Domínios para ativação",
  activation_domain_description:
    "Selecione o domínio que deseja realizar a ativação do Conecta Control",
  selectionDomainInfo:
    "<strong>Todos</strong> os <strong>usuários e administradores</strong> do domínio selecionado, passarão pelas regras do Conecta Control",
  confirm_activation_dialog_title: "Confirmação de Ativação",
  confirm_activation_dialog_description:
    "A ativação do Control fará com que todas as regras de turnos e novas senhas entrem em vigor imediatamente. Todos os colaboradores da empresa serão impactados. Deseja continuar?",
};

const activationStepsMs = {
  ms_ways_to_activate_control:
    'É possível ativar o ativar o Control para a Microsoft de duas maneiras: Pelo "Powershell" ou pelo "Graph Explorer".',
  ms_with_powershell:
    'A ativação por um terminal "Powershell" é feita executando os seguintes comandos:',
  ms_install_module: 'Instalação do módulo "Microsoft.Graph"',
  ms_module_authorize: "Conectar para autorizar",
  ms_control_activate: "Ativação do Control",
  ms_select_domain:
    "A ativação é feita em um único domínio de sua empresa. Selecione o domínio para atualizar o código.",
  ms_domain_already_federated:
    'Caso algum domínio já esteja como "Federated", é necessário remover a federação. Selecione o dóminio e utlize o comando abaixo:',
  ms_command_to_deactivate:
    "Esse é o mesmo comando para desativar o Control para Microsoft.",
  ms_with_graph_explorer:
    'A ativação pelo "Graph Explorer" é feita com uma requisição com as informações fornecidas abaixo e utilizando a ferramenta do link ',
  ms_rest_api_activate_headers_key:
    "Adicione como cabeçalho das requisições a chave ",
  ms_rest_api_activate_headers_value: "e o valor ",
  ms_rest_api_scopes_required:
    "É necessário dar permissão para os seguintes escopos:",
  ms_rest_api_activate:
    'Selecione o método da requisição "POST" e insira o seguinte endereço da requisição (API):',
  ms_rest_api_activate_body:
    "Copie e cole o corpo da requisição (body) e execute a requisição",
  ms_rest_api_deactivate:
    'Para desativar, troque o método da requisição para "PATCH" e o endereço da requisição (API) para o seguinte ',
  ms_rest_api_deactivate_body:
    "Troque também o corpo da requisição (body) para ",
  domain: "Domínio",
  domains: "Domínios",
  select_a_domain: "Selecione um domínio",
  select_one_or_more_domains: "Selecione um ou mais domínios",
  usersNotFoundOnSelectedDomain:
    "Nenhum usuário encontrado no domínio selecionado",
};

/* TABLE HEADERS EM GERAL */
const headers = {
  name: "Nome",
  email: "E-mail",
  date: "Data do registro",
  hour: "Hora",
  ip: "Endereço de IP",
  last_login: "Último login",
  permissions: "Permissões",
  admin: "Administrador",
  member: "Membro",
  group: "Turno",
  username: "Nome de usuário",
  status: "Status",
  active: "Ativo",
  not_syncronized: "Não sincronizado",
  actives: "Ativos",
  disabled: "Inativo",
  author: "Autor",
};

const customizeLogin = {
  uplaod_img_label: "Faça upload da logo da sua empresa",
  pers_login_title: "Personalize a página de login da sua empresa",
  pers_login_subtitle:
    "Altere as configurações da sua página de login e visualize em tempo real",
  change_existing_infos: "Alterar informações existentes",
  primary_color: "Cor do botão primário",
  secondary_color: "Cor do botão secundário",
  text_color: "Cor dos textos",
  remove_company_logo: "Remover logo da empresa",
  remove_background_image: "Remover imagem de fundo",
  remove_logo_error: "Erro ao remover a logo da empresa",
  remove_image_error: "Erro ao remover a imagem de fundo",
  crop: "Cortar",
  cut_out: "Recortar",
  crop_image: "Recortar imagem",
  remove_image: "Excluir imagem",
  undo_logo_changes: "Desfazer alterações",
  remove_current_logo: "Excluir logo atual",
  remove_current_background_image: "Excluir imagem atual",
  logo_height_placeholder: "Insira a altura em pixels",
  logo_width_placeholder: "Insira a largura em pixels",
  reset_image_dimensions: "Resetar dimensões da imagem",
  image_size_error: "O tamanho da imagem deve ser inferior a 2 MB!",
  view_in_full_screen: "Visualizar em tela cheia",
  background_image: "Imagem de fundo",
  background_image_description:
    "Utilize uma imagem personalizada na sua tela de login.",
  recommendations: "Recomendações:",
  image_color_recomendation:
    "Utilizar uma imagem clara (preferência por cor branca).",
  image_resolution_recomendation:
    "A resolução da imagem deve ser alta (full hd), para não haver distorções.",
  more_info: "Mais informações",
  powered_by_suite: "Essa página está sendo gerenciada por Conecta Suite.",
  logo_height: "Altura da logo",
  logo_width: "Largura da logo",
  logo: "Logo",
};

/* LABELS */
const labels = {
  greater_than_rule: "O valor não pode ser maior que ",
  less_than_rule: "O valor não pode ser menor que ",
  access_time: "Tempo de acesso",
  time_unit: "Unidade de medida",
  time_unit_checkbox_text: "Definir tempo de acesso após o login",
  time_unit_checkbox_help_text:
    "Marque essa opção para definir o tempo que o usuário permanecerá logado após fazer o login",
  automatic: "Automático",
  company_logo: "Logo da empresa",
  screen_colors: "Cores da tela",
  logo_description:
    "Faça upload de uma imagem e mostre a identidade visual da sua marca aos usuários.",
  screen_color_description: "Personalize as cores da tela de login.",
  height: "Altura",
  width: "Largura",
  upload_image_title: "Adicione uma imagem nos formatos .jpg, .jpge, .png, etc",
  logo_image: "Imagem da logo",
  upload_image: "Fazer upload da imagem",
  description_height_error: "Por favor, insira uma altura entre 40 e 500.",
  description_width_error: "Por favor, insira uma largura entre 40 e 500.",
  restore_default: "Restaurar padrão",
  password: "Senha",
  type_email: "Digite seu e-mail",
  type_password: "Digite sua senha",
  access: "Acessar",
  disabled_log: "DESATIVADO",
  active_log: "ATIVADO",
  leave_without_save_changes:
    "Algumas alterações não foram salvas. Deseja sair sem salvar?",
  are_you_sure: "Você tem certeza que deseja",
  confirm_this_action: "confirmar esta ação",
  sync: "Sincronizar",
  advanced: "Avançado",
  note: "Observação: ",
  type_paste_or_search: "Digite, cole ou pesquise",
  next_logout: "Próximo logout definido para",
  to_cancel_this_edit: 'Para cancelar a edição, clique em "Cancelar".',
  selected_filters: "Filtros selecionados: ",
  choose: "Escolher",
  company_profile_label: "Atualize informações da empresa e de faturamento",
  view_more: "Ver mais",
  password_strength: "Força da senha",
  password_min_char: "A senha não possui o número mínimo de caracteres.",
  show_users_without_group: "Ver usuários sem turno",
  is_google_admin_label:
    "Indica se o usuário possui ou não permissão de administrador no painel do Google Workspace. Esta configuração só pode ser feita diretamente no painel do Google.",
  is_active_user_label:
    "Indica que a conta deste usuário foi suspensa pelo administrador no painel do Google Workspace.",
  not_syncronized_ms_label:
    "Indica que o usuário ainda não foi criado na Microsoft.",
  suspended: "Suspenso",
  function: "Função",
  all_of: "Todos os ",
  page_selected: " nesta página estão selecionados. ",
  table_selected: " da tabela estão selecionados.",
  table_selected_snackbar: " da tabela foram selecionados.",
  select_all_of: "Selecionar todos os ",
  clear_selection: "Limpar seleção",
  add: "Adicionar",
  continue: "Continuar",
  copy: "Copiar",
  define: "Definir",
  define_as: "Definir como",
  define_password_policy_group:
    "Defina a política de expiração de senha para esse turno",
  details: "Detalhes",
  to_view: "Ver",
  preview: "Visualização",
  match: "Corresponde a",
  never: "Nunca",
  set_quantity: "Definir quantidade",
  or: "ou",
  basic_info: "Informações básicas",
  access_permissions: "Permissões de acesso",
  password_policy: "Política de senha",
  has_to_change_password_by_period:
    "Os participantes deste turno devem trocar suas senhas a cada ",
  day_of_week: "Dia da semana",
  month: "mês",
  monthly: "Mensal",
  days: "dias",
  weeks: "semanas",
  years: "anos",
  hours: "horas",
  minutes: "minutos",
  day: "dia",
  week: "semana",
  year: "ano",
  minute: "minuto",
  each: "cada",
  today: "Hoje",
  incorrect_dates: "Uma ou mais datas inseridas está incorreta",
  choose_days: "Escolher os dias",
  choose_day: "Escolher o dia",
  days_upper: "Dias",
  holiday: "Feriado",
  special_date: "Data especial",
  only_today: "Somente hoje",
  everyday: "Todos os dias",
  this_week: "Esta semana",
  this_month: "Este mês",
  this_day: "Este dia",
  everyday_in_this_week: "Todos os dias desta semana",
  weekly: "Semanal",
  start_hour: "Hora inicial",
  end_hour: "Hora final",
  start_date: "Data inicial",
  end_date: "Data final",
  loading: "Carregando...",
  no_data: "Não há dados para exibição",
  no_results: "Sem resultados",
  new_password: "Nova senha",
  confirm_new_password: "Confirme a nova senha",
  confirm: "Confirmar",
  request_password_change_label: "Trocar de senha no primeiro acesso",
  no: "Não",
  required_field: "Campo obrigatório",
  indicates_required_field: "indica um campo obrigatório",
  invalid_email: "E-mail inválido",
  invalid_phone_number: "Número de telefone inválido",
  max_20_chars: "Máximo de 20 caracteres",
  code_6_digits: "O código deve conter 6 dígitos.",
  passwords_do_not_match: "As senhas não coincidem",
  logout_settings: "Configurações de logout",
  access_settings: "Configurações de acesso",
  rules: "Regras",
  no_rules_for_today: "Nenhuma encerramento de sessão definido para hoje",
  no_autologout: "Nenhum encerramento automático definido",
  participants: "Participantes",
  participant: "Participante",
  allowed_ip_addresses: "Endereços IP permitidos",
  holidays_and_special_dates: "Feriados e datas especiais",
  special_dates_step_1: "Selecionar feriados",
  special_dates_step_2: "Inserir feriados manualmente",
  special_dates_step_1_description:
    "Utilize o calendário ou a tabela para selecionar datas especiais. O botão abaixo seleciona os feriados apenas.",
  special_dates_step_2_description:
    'As datas selecionadas também são exibidas no campo abaixo. Para salvar suas alterações, clique que em "Salvar".',
  special_dates_table_title: "Feriados e datas comemorativas",
  special_dates_get_error: "Não foi possível obter os feriados.",
  special_dates_select_holidays: "Selecionar apenas feriados nacionais",
  direct_participants: "Participantes diretos",
  admins: "Administradores",
  business_hours: "Jornada de trabalho",
  auto_logout: "Logout automático",
  auto_logout_after_closing_window: "Logout automático",
  auto_logout_after_closing_window_description:
    "usuário fechou a janela de gerenciamento",
  no_title: "Sem título",
  yes: "Sim",
  all_day: "O dia todo",
  list_ip: "Lista de IPs permitidos",
  invalid_request:
    "A solicitação é inválida e não pôde ser processada pelo servidor, verifique suas permissões de acesso e tente novamente mais tarde.",
  that_is_all_we_know: "Isso é tudo que sabemos.",
  this_is_an_error: "Isto é um erro",
  edition: "Edição",
  remove: "Remover",
  combobox_helper:
    "Após digitar, pressione <code>ENTER</code> ou <code>ESPAÇO</code> para inserir um novo item.",
  date_format: "Formato da data: DD/MM/AAAA",
  reset_selection: "Redefinir seleção",
  confirm_password: "Confirmar senha",
  result: "Resultado",
  no_date: "Nenhuma data para exibir",
  except: "exceto",
  set_up: "Configurar",
  no_group: "Nenhum turno",
  you_dont_have_groups: "Você não possui turnos",
  notice_to_employees_title: "Avise os colaboradores",
  notice_to_employees_description: `Informe aos colaboradores sobre as mudanças de acesso ao workspace. Não se preocupe, clique em "Saiba mais" e veja uma sugestão de mensagem para você compartilhar`,
  check_as_complete: "Marcar como concluído",
  your_answer: "Sua resposta",
  call_support: "Em caso de dúvidas, chame o suporte!",
  vacation_settings_tip:
    "Selecione as datas de <strong>inicio</strong> e <strong>fim</strong> do período de férias",
  vacation_placeholder: "Selecionar período de férias",
  from: "De",
  until: "até",
  reason: "Motivo",
  comment: "Comentário",
  user_block_hour: "Fora do horário permitido",
  user_success: "Usuário logado",
  user_wrong_password: "Senha inválida",
  user_on_vacation: "Usuário em férias",
  groups_without_rules: "Sem regra de horário definida",
  user_disabled_domain: "Domínio desabilitado",
  user_block_day: "Fora do horário permitido",
  user_special_block_day: "Dia especial",
  user_block_ip: "IP não permitido",
  user_without_group: "Turno não autorizado para login",
  user_without_active_group: "Não pertence a nenhum turno ativo",
  user_not_found: "Usuário não encontrado",
  access_logs: "Logs de acesso",
  last_seven_days: "Últimos 7 dias",
  last_three_months: "Últimos 3 meses",
  last_six_months: "Últimos 6 meses",
  download_csv: "Baixar CSV",
  ip_address_description:
    "Endereço exclusivo que identifica um dispositivo na internet",
  browser: "Navegador",
  operational_system: "Sistema operacional",
  operational_system_description:
    "O sistema operacional é o programa que permite que você se comunique com seu computador.",
  event: "Evento",
  login_attempt: "Tentativa de login",
  user_block_navigator: "Navegador não permitido para acesso",
  user_block_operational_system:
    "Sistema operacional não autorizado para acesso",
  user_unknown_ponto_mais: "Usuário não cadastrado na VR Gente | PontoMais",
  user_unknown_senior: "Usuário não encontrado na Senior",
  user_block_ponto_mais: "Verifique seu registro na VR Gente | PontoMais",
  user_block_senior:
    "Verifique seu registro no aplicativo Marcação de Ponto 2.0",
  unknown_error: "Erro desconhecido",
  user_logout_success: "Deslogou",
  logout_success: "Logout forçado",
  advanced_auto_logout: "Logout automático",
  single_access_logout: "Logout automático",
  author_user: "Autor",
  author_description: "O usuário que executou a ação",
  description: "Descrição",
  force_logout_description: "encerrou a sessão de",
  closed_office: "Sessão encerrada por regra de turno",
  end_of_single_access: "Fim do acesso temporário",
  pontomais_auto_logout: "Logout automático",
  senior_auto_logout: "Logout automático",
  pontomais_auto_logout_description: "Sessão encerrada por integração de ponto",
  more_description:
    "Para usar esta opção, é necessário ter no mínimo um usuário selecionado",
  watch_the_video: "Assista ao vídeo",
  watch_explanation: "Assistir explicação",
  vacation_mode_video_title:
    "Modo férias para usuários Google Workspace com o Conecta Control",
  dismiss: "Dispensar",
  ip_warning:
    "Para saber seu IP atual, <strong><a href='https://www.dnsleaktest.com' target='_black'>clique aqui</a></strong>. Caso tenha duvidas sobre como funcionam os endereços de IP na sua empresa, consulte sua equipe de TI.",
  start: "Início",
  end: "Fim",
  duration: "Duração",
  records: "Registros",
  hi: "Olá",
  schedule_meeting_description:
    "boas vindas ao Conecta Control! Como é a sua primeira vez aqui, você pode agendar uma reunião com o nosso time para saber mais sobre a ferramenta. Gostaria de agendar uma reunião?",
  maybe_later: "Talvez mais tarde",
  user_comments: "Deixe um comentário",
  thanks_for_feedback: "Agradecemos pelo feedback!",
  new_feedback: "Novo feedback",
  use_default_password: "Usar senha padrão",
  cancel_activation_sso_async: "cancelou ativação automatica do Control",
  automatic_activation: "Ativação automática",
  user_activate_sso_async: "ativou o control de forma assíncrona",
  suspended_account: "Conta suspensa",
  contact: "Entre em contato",
  contact_for_resolution:
    "Entre em contato conosco para resolver problemas de pagamento",
  expired_code: "Código expirado",
  code_expires: "O código expira em",
  fix: "Corrigir",
  confirmAndNext: "Confirmar e avançar",
};

const scopesDialog = {
  scopesDialog_title:
    "Para completar seu login, aceite todas as permissões do Conecta Control no Google Admin Console.",
  scopesDialog_permissions:
    "Precisamos dessas permissões para oferecer uma experiência completa.",
  scopesDialog_privacy:
    "Sua privacidade e segurança são nossas prioridades, e você pode revisar as permissões a qualquer momento.",
  scopesDialog_faq: `Se tiver dúvidas, acesse o <a href="https://conectasuite.com/perguntas-frequentes/escopos-google-workspace/" target="_blank"><b>FAQ</b></a> ou entre em contato conosco.`,
  scopesDialog_acceptAll: `Acesse o Google Admin Console e clique em "Permitir acesso" para prosseguir.`,
  scopesDialog_goToAdminConsole: "Ir para o painel Google Admin Console",
  scopesDialog_goToPanel: "Ir para o painel",
  scopesDialog_msDidNotRespond: "A Microsoft não respondeu",
  scopesDialog_checkMsInstallation:
    "Verifique no painel Entra Id de seu Microsoft 365 se o aplicativo foi instalado em seu locatário, se as permissões estão concedidas e se seu usuário tem acesso ao aplicativo.",
  scopesDialog_inCaseMsIsInstalled:
    "Caso tudo aparente estar correto, aguarde mais alguns minutos pela propagação dos dados e tente realizar o login novamente.",
};

const reports_page = {
  reports_loginlogout_title: "Relatório de login e logout",
  reports_loginlogout_description: "Registros de login e logout dos usuarios",
  reports_users_title: "Relatório de usuários",
  reports_users_description: "Registros de ações nos usuários",
  reports_groups_title: "Relatório de turnos",
  reports_groups_description: "Registros de ações nos turnos",
  reports_access_time_title: "Relatório de tempo de acesso",
  reports_access_time_description:
    "Registros de tempo de acesso dos usuários por dia",
  folders_sharing: "Arquivos e pastas",
  folders_sharing_description: "Relatório de compartilhamento externo",
  shared_files: "Arquivos compartilhados",
  files_shared_externally: "Arquivos compartilhados externamente",
  full_size: "Tamanho total",
  sharing: "Compartilhamento",
  users_with_access: "Usuários com acesso",
  size: "Tamanho",
  extension: "Extensão",
  anyone_with_link: "Qualquer pessoa com o link",
  external: "externo",
  internal: "interno",
  access_time_start: "Primeiro login.",
  access_time_end: "Primeiro logout após o último login.",
  access_time_duration:
    "Período em que o usuário esteve conectado à Google, podendo ser diferente do tempo em que esteve na máquina.",
  access_time_duration_missing_data:
    "Duração estimada: sessão continua até o fim do dia.",
  access_time_duration_not_finished:
    "Duração parcial: sessão continua ativa no dia corrente.",
  access_time_duration_cannot_calculate:
    "Sem início: falta registro de login para calcular a duração da sessão no dia.",
  upgrade_plan: "Contratar plano",
  report_user_permissions_title: "Relatorio de permissões de usuário",
  report_customize_login_title: "Relatório de personalização da tela de login",
  customize_login_label: "Personalização de login",
};

/* AÇÕES EM GERAL */
const button_actions = {
  add_filter: "Adicionar filtros",
  next: "Avançar",
  previous: "Voltar",
  more: "Aplicar aos selecionados",
  add_selected_to_group: "Adicionar {count} usuários selecionados a um turno",
  add_user_to_group: "Adicionar usuário a um turno",
  add_users_to_shift: "Adicionar usuários a um turno",
  manage_user_groups: "Gerenciar turnos do usuário",
  remove_selected_from_this_group: "Remover selecionados de um turno",
  remove_user_from_this_group: "Remover usuário deste turno",
  remove_user_from_a_group: "Remover usuário de um turno",
  select_all: "Selecionar todos os",
  apply: "Aplicar",
  active_control: "Ativar Conecta Control",
  confirm_and_active_control: "Confirmar e Ativar Conecta Control",
  yes_activate_control: "Sim, ativar Control",
  back_to_activation: "Voltar para ativação",
  inactive_control: "Desativar Conecta Control",
  change_password: "Alterar senha",
  reset: "Redefinir",
  reset_password: "Redefinir senha",
  reset_password_label:
    "A nova senha deve obedecer as regras estabelecidas em <b>Configurações de senha > Configurar força de senha</b>.",
  ask_force_logout_reset_password: "Deslogar de todos os dispositivos",
  ask_force_logout_reset_password_tooltip:
    "Quando habilitada, esta opção irá deslogar a conta do usuário de todos os dispositivos conectados",
  force_logout_to_all_users: "Forçar logout de todos os usuários",
  redirect_to_force_logout: "Selecionar usuários para forçar o logout",
  ask_reset_password_first_access: "Alterar a senha ao fazer o próximo login",
  ask_reset_password_first_access_tooltip:
    "Habilitando esta opção, o próximo login deverá inserir a senha aqui definida para em seguida conseguir redefinir sua própria senha manualmente.",
  request_password_change: "Solicitar troca de senha",
  change_user_password: "Trocar a senha do usuário",
  user_password_change: "Alteração de senha",
  change_users_password: "Trocar a senha dos usuários",
  request_logout: "Forçar logout",
  request_users_logout: "Forçar logout dos usuários",
  grant_single_access: "Liberar acesso temporário",
  remove_single_access: "Remover acesso temporário",
  edit_groups: "Editar turnos deste usuário",
  change_permissions: "Alterar permissões",
  changes: "mudanças",
  close: "Fechar",
  save: "Salvar",
  cancel: "Cancelar",
  add_selected: "Adicionar usuários selecionados",
  more_link_text: "Saiba mais",
  verify: "verificar",
  disable_control: "Desativar Control",
  click_here: "Clique aqui",
  click_to_check_to_conclude: "Clique para marcar como concluído",
  click_to_uncheck_to_conclude: "Clique para remover item de concluídos",
  allow_access: "Permitir acessos",
  go_to_suite: "Acessar o Conecta Suite",
  see_plan: "VER PLANO",
  see_integrations: "VER INTEGRAÇÕES",
  request_single_access: "Solicitar acesso temporário",
  user_request_single_access: "Usuário solicitou o acesso temporário",
  user_email_hint: "Insira o endereço de e-mail que você deseja acessar",
  requested_single_access: "solicitou o acesso temporário",
  requestSingleAccess: "Solicitou acesso temporário",
  request_password_change_text: "Solicitar troca de senha",
  user_request_password_change: "Usuário solicitou uma troca de senha",
  requested_password_change_description: "solicitou uma troca de senha",
  to_admins: "para os administradores",
  reports: "Relatórios",
  reports_description: "Acompanhe relatórios de atividades dos usuários",
  reports_loginlogout: "Login e logout",
  reports_users: "Usuários",
  reports_groups: "Turnos",
  reports_access_time: "Tempo de acesso",
  dismiss_text: "Dispensar",
  restore_default_color: "Restaurar cor padrão",
  activate_selected_shifts: "Ativar turnos selecionados",
  deactivate_selected_shifts: "Desativar turnos selecionados",
  cancel_schedule_activation: "Cancelar agendamento",
  continue_without_code: "Continuar sem código",
  go_to_marketplace: "Ir para o Marketplace",
};

const password_settings_page = {
  set_default_password: "Configurar senha padrão",
  password_settings_subtitle:
    "A senha temporária é a senha que os seus usuários terão de utilizar ao fazer login a primeira vez ou ao solicitarem a troca de suas senhas.",
  defineTemporaryPasswordTip:
    "Os seus usuários terão de utilizar essa senha ao fazer o primeiro login.",
  default_password: "Senha padrão",
  new_initial_password: "Nova senha temporária",
  password_security: "Configurar força de senha",
  password_settings_subtitle2:
    "Escolha quais padrões as senhas dos seus usuários devem possuir",
  min_len_label: "Número mínimo de caracteres:",
  must_have_a_upper_letter: "Deve incluir pelo menos uma letra maiúscula.",
  must_have_a_lower_letter: "Deve incluir pelo menos uma letra minúscula.",
  must_have_a_number: "Deve incluir pelo menos um número.",
  must_have_a_symbol: "Deve incluir pelo menos um caractere especial.",
  chackbox_label: "As senhas devem conter:",
  checkbox_symbols_label: 'Caracteres especiais (Ex: “!, @, %, #")',
  checkbox_uppercase_label: "Letras maiúsculas (de “A“ a “Z“)",
  checkbox_lowercase_label: "Letras minúsculas (de “a“ a “z”)",
  checkbox_numbers_label: "Número (de “0” a “9”)",
  rename: "Renomear",
  disable: "Desativar",
  edit: "Editar",
  name: "Nome",
  delete: "Excluir",
  action_cannot_undo: "Não é possível desfazer essa ação.",
  action_can_undo: "Esta ação pode ser desfeita a qualquer momento.",
  activate: "Ativar",
  create: "Criar",
  success_update_default_password_message: "A nova senha foi salva",
  error_update_default_password_message:
    "Ocorreu um erro ao salvar a senha, tente novamente mais tarde.",
  success_update_password_strength:
    "Configurações de força de senha salvas com sucesso!",
  error_update_password_strength:
    "Ocorreu um erro ao salvar as configurações de senha, tente novamente mais tarde.",
  current_password: "Senha atual",
  has_initial_password: "Senha configurada",
  no_initial_password: "Nenhuma senha configurada",
  password_successful_copy: "A senha foi copiada",
  password_unable_to_copy: "Não foi possível copiar a senha, tente novamente",
  theAdminMustChangeHisOwnPassword:
    "<strong>Administradores que utilizam o domínio selecionado também serão afetados</strong>",
  adminsMustChangePassword:
    "<strong>Adminstradores no domínio selecionado também deverão trocar a senha</strong>",
};

const integrations_page = {
  your_token: "seuToken",
  logout_webhook_copied_successfully: "Webhook copiado com sucesso",
  current_webhook: "Link do webhook",
  clock_status_management:
    "Gerenciamento de estatus via WEBHOOK para serviço de ponto",
  activate_clock_status_management:
    "Ative este recurso e reduza o atraso na sincronização de estatus de ponto de seus colaboradores. Ao ativar, o Conecta Control gerenciará o estatus de ponto de seus colaboradores através das respostas do WEBHOOK.",
  disable_clock_status_management:
    "Ao desativar este recurso, o tempo de sincronização de estatus de ponto de seus colaboradores dependerá exclusivamente do seu provedor de ponto.",
  clock_status_management_warning:
    "Certifique-se de ter configurado o WEBHOOK em seu provedor de ponto antes de ativar esse recurso. Caso contrário, NÃO ative.",
  autologout_by_pontomais: "Autologout via VR Gente | PontoMais",
  request_integration_activation: "Solicitar ativação de integração",
  ponto_mais_control: "Controle de ponto com VR Gente | PontoMais",
  tangerino_control: "Controle de ponto com Sólides Tangerino",
  clock_time_control_unavailable:
    "A integração com ponto está disponivel somente para o plano Enterprise, faça o upgrade para liberar essa funcionalidade.",
  pontomais_token_not_found:
    "Não foi encontrado um Token da VR Gente | PontoMais",
  tangerino_token_not_found:
    "Não foi encontrado um token de integração da Sólides Tangerino",
  invalid_senior_credentials:
    "Credenciais do aplicativo Marcação de Ponto 2.0 invalidas",
  activate_integrations: "ATIVAR INTEGRAÇÕES",
  active_directory_unavailable:
    "A integração com Active Directory está disponivel somente para o plano Enterprise, faça o upgrade para liberar essa funcionalidade.",
  ponto_mais_integration_subtitle:
    "Configure o token VR Gente | PontoMais da conta de sua empresa para combinar o serviço de ponto eletrônico e os beneficios do Conecta Control.",
  tangerino_integration_subtitle:
    "Configure o token de integração da Sólides Tangerino da sua empresa, para combinar o serviço de ponto eletrônico e os beneficios do Conecta Control.",
  current_token: "Token atual",
  active_directory_sync: "Sincronização com Active Directory (AD)",
  active_directory_integration_subtitle:
    "Sincronize o Active Directory do Windows com o Conecta Control AD e simplifique a gestão dos usuários e de suas senhas",
  copy_paste_pontomais_autologout_function:
    "Para realizar o logout automático dos usuários após o registro de ponto de saída, copie e cole o texto abaixo na configuração de webhook de registro de ponto da VR Gente.",
  download_executable: "Download do executável",
  new_token: "Novo token",
  save_token: "Salvar token",
  ponto_mais_token_integration_title:
    "Salvar token de integração com a VR Gente | PontoMais",
  tangerino_token_integration_title:
    "Configurar token de integração com a Sólides Tangerino",
  update_ad_token_title: "Atualizar token do Active Directory",
  update_ad_token_action: "Atualizar AD Token",
  update_ad_token_confirm_phrase:
    "Você tem certeza que quer atualizar o token? Se sim, para manter a integração será preciso atualizar o token no seu servidor.",
  error_update_ad_token: "Não foi possível atualizar o AD token",
  success_update_ad_token: "Token atualizado com sucesso!",
  error_update_ponto_mais_token:
    "Erro ao atualizar o token da VR Gente | PontoMais",
  invalid_ponto_mais_token: "O token informado é inválido",
  success_update_ponto_mais_token:
    "Token da VR Gente | PontoMais atualizado com sucesso",
  success_update_token: "Token de integração salvo com sucesso",
  token_copied_successfully: "Token copiado com sucesso!",
  marcacao_ponto_senior_control:
    "Controle de ponto com Marcação de Ponto 2.0 HCM | Senior",
  senior_credentials_description:
    "Configure as credenciais do aplicativo registrado na Senior X Platform para combinar o serviço de ponto e os beneficios do Conecta Control",
  config_credentiais: "Configurar credenciais",
  edit_credentiais: "Editar credenciais",
  access_key: "Chave de acesso",
  secret: "Segredo",
  tenant_name: "Nome do tenant",
  success_update_senior_credentials: "Credenciais atualizadas com sucesso",
  clock_time_provider_credentials_not_found:
    "Não foi encontrado as credenciais de acesso do provedor de ponto selecionado",
};

const sso = {
  disable_sso_warning:
    "O Conecta Control esta desativado, as configurações no painel só serão aplicadas após a ativação por um administrador.",
  disable_sso_admin_warning:
    "O Conecta Control está desativado! Vá até as configurações",
  and_click_on: " e clique em ",
  activate_sso_warning: "Existem ",
  activate_sso_warning2: " sem turno atribuído.",
  activate_sso_warning3:
    " Usuários sem turno não passarão na verificação do Conecta Control.",
  activate_sso_success: "Conecta control ativado com sucesso!",
  activate_sso_error: "Não foi possível ativar o Conecta control",
  disable_sso_success: "Conecta control desativado com sucesso!",
  disable_sso_error: "Não foi possível desativar o Conecta control",
  activate_sso_label:
    "A ação a seguir irá ativar o controle de acesso para todos os usuários do seu workspace.",
  action_reversible:
    "Esta ação pode ser desfeita a qualquer momento por um administrador através do menu de configurações.",
  continue_activation: "Deseja continuar a ativação?",
  dont_force_logout: "Manter todos os usuários logados",
  force_users_logout:
    "Force o logout dos usuários para realizarem o login pelo Control:",
  schedule_activation_alert_description:
    "A ativação do Conecta Control está agendada para",
  cancel_schedule_activation_dialog_title: "Cancelar agendamento de ativação",
  no_shift_users_dialog_title: "Usuários sem turnos detectados!",
  no_shift_users_dialog_message:
    "O acesso dos colaboradores SEM TURNO será bloqueado após a ativação do Control. Deseja adicioná-los a um turno agora ou continuar mesmo assim?",
};

const errors = {
  unavailable_group_information: "Um erro ocorreu no carregamento deste turno",
  error_update_group:
    "Não foi possível salvar as alterações feitas neste turno",
  error_rules_update:
    "Não foi possível salvar as alterações nas regras deste turno",
  error_logout: "Não foi possível fazer o logout",
  error_create_group:
    "Não foi possível criar um novo turno, tente novamente mais tarde",
  error_load_groups:
    "Erro ao carregar os turnos, verifique suas permissões de acesso junto ao administardor",
  error_add_admin:
    "Não foi possível adicionar a permissão de administrador para este usuário, por favor, verifique suas permissões",
  error_remove_admin:
    "Não foi possível remover a permissão de administrador deste usuário, por favor, verifique suas permissões",
  error_user_page: "Um erro ocorreu no carregamento deste usuário",
  error_add_user_to_shift:
    "O usuário não pode ser adicionado neste turno. Tente novamente.",
  error_remove_user_from_group:
    "O usuário não pode ser removido deste turno. Tente novamente.",
  error_remove_users_from_group:
    "Os usuários não puderam ser removidos deste turno. Tente novamente.",
  error_participants_to_add_not_found:
    "Um ou mais endereços de e-mail informados não puderam ser adicionados",
  error_invalid_emails:
    "Um ou mais endereços de e-mail informados são inválidos, corrija-os e tente novamente.",
  error_invalid_emails_examples:
    "Os seguintes endereços de e-mail não são válidos:",
  some_emails_could_not_be_added: "Alguns e-mails não puderam ser adicionados",
  error_force_password_change:
    "Ocorreu um erro ao solicitar alteração das senhas dos usuários selecionados",
  error_change_password: "Erro ao trocar a senha, tente novamente",
  error_vacation_mode: "Erro ao ativar o modo férias, Tente novamente",
  error_remove_vacation_mode: "Erro ao remover o modo férias, Tente novamente",
  error_grant_single_access: "Erro ao liberar o acesso temporário.",
  error_remove_single_access: "Erro ao remover o acesso temporário.",
  service_unavailable:
    "Serviço não disponível. Por favor tente novamente mais tarde!",
  error_user_logs:
    "Ocorreu um erro ao carregar o histórico de login. Tente novemente mais tarde!",
  error_logout_users_suspended:
    "Não é possível forçar logout de usuários suspensos",
  starthour_later_than_endhour:
    "A Hora inicial não pode ser maior que a hora final",
  MISSING_LOGO: "Não foi possível salvar a sua logo",
  MISSING_PRIMARY_COLOR: "Não foi possível salvar cor primária",
  MISSING_SECONDARY_COLOR: "Não foi possível salvar cor secundária",
  not_found: "Não encontrado",
  error_save_senior_credentials:
    "Não foi possível finalizar a configuração, verifique as credenciais e tente novamente",
  error_default_password_sent:
    "Não foi possível enviar a senha padrao para os usuários, tente novamente",
  error_user_already_exists: "Usuário já existe em sua lista de usuários",
  error_shared_password:
    "Ocorreu um erro ao compartilhar as senhas por e-mail, tente mais tarde.",
  error_send_email_to_change_password:
    "Ocorreu um erro ao enviar os e-mails, aguarde um instante e tente novamente.",
  scope_error: "Não foi possível concluir a ação por falta de escopos",
  error_reports: "Ocorreu um erro ao carregar o relatório. Tente novamente",
  error_activation_async:
    "Ocorreu um erro durante o agendamento da ativação. Tente novamente",
  error_cancel_activation_async:
    "Ocorreu um erro durante o cancelamento da ativação automática. Tente novamente",
  INVALID_TANGERINO_TOKEN:
    "O token de integração informado não é válido! Verique o token e tente novamente",
};

const success = {
  success_config_update: "As configurações foram salvas.",
  success_group_update: "As alterações deste turno foram salvas",
  success_rules_update: "As regras deste turno foram atualizadas",
  success_logout: "Sessão do usuário encerrada",
  success_users_logout: "As sessões dos usuários ativos foram encerradas",
  success_create_group: "Turno criado com sucesso",
  success_add_admin: "Permissão de administrador concedida para",
  success_remove_admin: "Permissão de administrador removida de",
  success_remove_user_from_group: "O usuário foi removido do turno",
  success_remove_users_from_group: "Os usuários foram removidos do turno",
  success_add_user_to_a_group: "foi adicionado ao turno",
  success_add_user_to_many_groups: "foi adicionado a vários turnos",
  success_add_users_to_group:
    "Os usuários foram adicionados ao turno com sucesso",
  success_force_password_change:
    "A solicitação de alteração de senha foi aplicada aos usuários selecionados",
  success_change_password: "A senha foi atualizada",
  success_vacation_mode: "O modo férias foi aplicado com sucesso!",
  success_remove_vacation_mode: "O modo férias foi removido!",
  success_grant_single_access: "O acesso temporário foi liberado com sucesso!",
  success_remove_single_access: "O acesso temporário foi removido com sucesso!",
  success_request_single_access: "Sua solicitação foi realizada com sucesso!",
  success_request_password_change:
    "Sua solicitação de troca de senha foi enviada com sucesso!",
  saved_logo:
    "A sua logo foi salva e agora está disponível na página de login!",
  customize_login_success:
    "As alterações foram salvas e agora estão disponíveis na tela de login",
  remove_logo_success: "Logo da empresa removida com sucesso!",
  remove_image_success: "Imagem removida com sucesso!",
  save_senior_credentials: "As credenciais foram salvas com sucesso",
  default_password_sent:
    "A senha padrão foi enviada para os usuários com sucesso",
  success_ms_user_created: "Usuário foi criado e sincronizado com sucesso!",
  shared_password_success:
    "Uma senha aleatória será gerada e compartilhada com segurança por e-mail com os seus colaboradores. Em breve, eles receberão a senha para acesso.",
  shared_email_password_success:
    "Os e-mails foram enviados para os seus colaboradores.",
  success_schedule_activation:
    "A ativação do Conecta Control foi agendada com sucesso!",
  success_cancel_schedule_activation:
    "O agendamento de ativação automática foi cancelado com sucesso",
};

const warnings = {
  warning_control_user_created:
    "Usuário foi criado, mas não foi possível sincronizá-lo. Tente mais tarde sincronizar usuários, ou entre em contato com o suporte.",
};

const plan_status = {
  user_expired_trial: "Período de Trial expirou",
  disabled_or_not_found: `Este produto não esta disponível para sua empresa. Contrate um <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plano</a> para ativa-lo ou entre em contato com nossa equipe comercial para continuar usando o Conecta Control: experience@conectasuite.com.`,
  expired_trial: `O seu período de trial expirou. Contrate um \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plano para ativa-lo</a> \
    ou entre em contato com nossa equipe comercial para continuar usando o Conecta Control: experience@conectasuite.com`,
  expiring_trial: `[Seu trial expira em <strong>] DD/MM/YYYY. [</strong> Contrate um \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plano para ativa-lo</a> \
    ou entre em contato com nossa equipe comercial para continuar usando o \
    Conecta Control: experience@conectasuite.com]`,
  company_not_found:
    "Sua empresa não foi encontrada no Conecta Suite. Entre em contato com nossa equipe comercial para \
    continuar usando o Conecta Control: experience@conectasuite.com",
  product_unavailable: "Esse produto não está ativo para a sua empresa",
  go_to_suite_plans: "Contrate um plano para ativá-lo",
  make_plan_upgrade: "Faça o upgrade de plano",
  contract_a_plan: "Contratar um plano",
  know_the_plans: "Conhecer os planos",
  contract_control: "Contrate já o Conecta Control!",
  more_resources_dense:
    "Mais recursos: controle o acesso por tempo, local e navegador ao Workspace",
  more_resources:
    "Desbloqueie mais recursos, controle o acesso por tempo, local e navegador ao Workspace com Conecta Control",
  contract_expired_trial:
    "Adquira um plano para continuar aproveitando todas as funcionalidades dos produtos Conecta Suite!",
  contract_plan_while_trial:
    "Garanta já seu plano sem perder seus dias restantes de teste gratuito. A partir de R$2,50/usuário/mês!",
  plan_is_expired_trial: "Ops, seu período de teste terminou!",
  enjoying_control: "Está gostando do Conecta Control?",
  last_trial_day: "Último dia de trial",
  expired: "Expirado",
  testing_text: "Você está testando",
  days_remaining: "Dias restantes",
  remaining_day: "Dia restante",
  company_information: "Informações da empresa",
  request_activation: "Solicitar ativação",
  test_ends_in: "Trial termina em",
  test_ends_today: "Trial termina hoje",
  test_ended_in: "Trial terminou em",
  licenses_number: "N° de licenças",
  created_at: "Criado em",
  products: "Produtos",
  activation_error_disabled_plan_title:
    "Ops, não foi possível ativar o controle de acesso",
  plan_details: "Detalhes do Plano",
  plan: "Plano",
  plan_payment: "Plano de pagamento",
  /* Plan Periodic */
  flex: "Mensal",
  annual: "Anual",
  trial: "Trial",
  quartely: "Trimestral",
  semester: "Semestral",
  biennium: "Bienal (2 anos)",
  extra: "Extras",
};

const snackbarActivationErrorMessages = {
  DISABLED_OR_NOT_FOUND:
    "Não foi possível ativar o controle de acesso pois o Conecta Control não faz parte de nenhum plano contratado ou em teste",
  EXPIRED_TRIAL:
    "Não foi possível ativar o Conecta Control pois o período de teste expirou",
};

const reset_password = {
  reset_description:
    "Escolha uma senha forte, de preferência que contenha simbolos, números, letras maiúsculas e minúsculas.",
  REQUIRED_BASIC_AUTH: "É necessário se autenticar para realizar esta operação",
  INCORRECT_CREDENTIALS: "Usuário ou senha incorretos",
  INVALID_PASSWORD: "A senha não atende um ou mais requisitos",
  SAME_PASSWORD: "Nova senha idêntica à atual.",
  USER_NOT_FOUND: "O usuário informado não foi encontrado",
  REQUEST_LIMIT_EXCEEDED:
    "Você atingiu o número máximo de requisições permitidas para esta operação, por favor, tente novamente em alguns minutos.",
  min_len: "A senha deve conter o número mínimo",
  min_len_sufix: " de caracteres.",
  has_upper: "A senha deve conter pelo menos uma letra maiúscula.",
  has_lower: "A senha deve conter pelo menos uma letra minúscula.",
  has_number: "A senha deve conter pelo menos um número.",
  has_symbol: "A senha deve conter pelo menos um caractere especial.",
  at_least_an_upper: "Pelo menos uma letra maiúsucla",
  at_least_an_lower: "Pelo menos uma letra minúscula",
  at_least_an_number: "Pelo menos um número",
  at_least_an_symbol: "Pelo menos um caractere especial",
  is_the_same: "A nova senha deve ser diferente da atual.",
};

const productNames = {
  conecta_suite: "Conecta Suite",
  conecta_control_mobile: "Advanced Logout",
  conecta_control: "Conecta Control",
  conecta_sign: "Conecta Sign",
  conecta_ad_sync: "Active Directory Integration",
  conecta_track: "Conecta Track",
  integracao_ponto_mais: "VR Gente | PontoMais Integration",
  sign_automation: "Sign Automation",
};

const priceChangeWarning = {
  priceChangeWarning:
    "A partir do dia <strong>1º de julho de 2024</strong>, os preços dos planos Standard, Security e Enterprise da Conecta Suite serão reajustados conforme tabela disponível no site",
  noActionNeeded: "Nenhuma ação é necessária.",
  willingToHelp:
    "Estamos à disposição para esclarecer suas dúvidas pelo e-mail",
  thanks4BeeingClient: "Agradecemos por ser cliente Conecta Suite.",
  iUnderstood: "Eu entendi",
  priceChangeSubtitle: "Aviso sobre alteração no preço dos planos",
  priceAdjustment: "Reajuste no valor dos Planos",
};

const companyProfile = {
  companyProfile: "Perfil da Empresa",
  companyInfo: "Informações da empresa",
  cnpj: "CNPJ",
  site: "Site",
  corporate: "Razão social",
  financeEmail: "E-mail do financeiro",
  adminEmail: "E-mail do admin",
  neighborhood: "Bairro",
  address: "Endereço",
  number: "Número",
  complement: "Complemento",
  city: "Cidade",
  state: "Estado",
  country: "País",
  zipCode: "CEP",
  companyInfoTitle: "Informações da empresa",
  companyBillingTitle: "Faturamento",
  companyBillingSubtitle: "Gerencie seus dados de pagamento.",
  billingInfo: "Informações de Faturamento",
  requiredEmail: "E-mail é obrigatório",
  clearFields: "Limpar campos",
  missingCNPJAlert:
    "Esta empresa <b>não possui CNPJ cadastrado</b>. Para prosseguir com a compra é necessário preencher as informações obrigatórias.",
  notRegistered: "Não cadastrado",
  dataUpdated: "Dados atualizados com sucesso!",
  fail_to_update_company_in_suite:
    "Erro ao atualizar as informações da empresa",
  DOWNGRADE_BLOCK:
    "Não é possível realizar downgrade de plano na modalidade anual. Por favor, entre em contato via e-mail para experience@conectasuite.com.",
  INVALID_SUBSCRIPTION: "Troca de periodicidade de plano não permitida",
  fillingRequiredFields: "Preencha os dados obrigatórios",
  requiredCNPJ: "CNPJ é obrigatório",
  insertingCNPJ: "inserindo CNPJ..",
  NOT_IN_CACHE: "Não é possível ler os dados deste CNPJ",
  LIMIT_EXCEEDED: "Tente novamente em um minuto",
  REJECTED_CNPJ: "CNPJ rejeitado pela receita federal",
  INVALID_CNPJ: "CNPJ inválido",
  BAD_REQUEST_CNPJ: "CNPJ não informado",
  INVALID_COMPANY_CNPJ:
    "Não foi possível concluir a contratação do plano pois a empresa contratante não possui CNPJ cadastrado no sistema.",
};

export const pt_BR = Object.assign(
  {},
  feedback,
  login,
  auth,
  phoneVerification,
  navbar,
  general,
  users,
  conecta,
  reports_page,
  button_actions,
  sidebar,
  headers,
  home,
  labels,
  groups,
  tour,
  password_settings_page,
  integrations_page,
  errors,
  success,
  warnings,
  sso,
  plan_status,
  welcome,
  activationSteps,
  activationStepsMs,
  adminPermissions,
  reset_password,
  customizeLogin,
  productNames,
  snackbarActivationErrorMessages,
  priceChangeWarning,
  scopesDialog,
  companyProfile
);
