import router from "../../router";

const state = () => ({
  groups: [],
  loading: {
    groups: false,
    selected_group: false,
    create_group: false,
  },
  selectedGroup: {},
  edit: {
    access_permissions: false,
    participants: false,
  },
});

const getters = {
  groups: (state) => state.groups,
  hasGroups: (state) => state.groups.length > 0,
  groupsKeyNamePairObject: (state) => {
    const groups = state.groups.reduce((acc, { key, name }) => {
      acc[key] = name;
      return acc;
    }, {});
    return groups;
  },
  loadingGroups: (state) => state.loading.groups,
  selectedGroup: (state) => state.selectedGroup,
  selectedGroupName: (state) => state.selectedGroup.name,
  loadingSelectedGroup: (state) => state.loading.selected_group,
  loadingCreateGroup: (state) => state.loading.create_group,
  editRules: (state) => state.edit.access_permissions,
  editParticipants: (state) => state.edit.participants,
  editGroupMode: (state) =>
    Object.values(state.edit).some((item) => item === true),
  currentGroupEdit: (state) =>
    Object.entries(state.edit).find((item) => item[1] === true) || false,
  specialDates: (state) =>
    state.selectedGroup.list_special_dates
      ? state.selectedGroup.list_special_dates
      : [],
  allowedIPs: (state) => state.selectedGroup.list_ip || [],
  allowedBrowsers: (state) => state.selectedGroup.allowed_browsers || [],
  allowedOs: (state) => state.selectedGroup.allowed_os || [],
};

// actions
const actions = {
  async activateOrDeactivateShifts({ commit, dispatch }, payload) {
    const url = "/groups/bulk-activate-or-deactivate";
    commit("setLoadingGroups", true);
    await this._vm.$axios
      .patch(url, payload)
      .then(() => {
        dispatch(
          "setSnackbarDefault",
          {
            message: `${payload.action}_shifts_successfully`,
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("vuex: activateOrDeactivateShifts(): ", error);
        dispatch(
          "setSnackbarDefault",
          {
            message: `${payload.action}_shifts_error`,
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        dispatch("getGroups");
        commit("setLoadingGroups", false);
      });
  },

  async createGroup({ commit, dispatch }, payload) {
    commit("setLoadingCreateGroup", true);
    commit("setLoadingSelectedGroup", true);
    await this._vm.$axios
      .post("/groups", payload)
      .then(({ data }) => {
        commit("insertCreatedGroupInGroups", data);
        dispatch(
          "setSnackbarDefault",
          {
            message: "success_create_group",
            color: "success",
          },
          { root: true }
        );
        router.push(`/groups/${data.key}`);
      })
      .catch((error) => {
        dispatch(
          "setSnackbarDefault",
          {
            message: "error_create_group",
            color: "error",
          },
          { root: true }
        );
        console.error("vuex: createGroup(): ", error);
      })
      .finally(() => {
        commit("setLoadingCreateGroup", false);
        commit("setLoadingSelectedGroup", false);
      });
  },
  async getGroups({ commit, dispatch }) {
    commit("setLoadingGroups", true);
    await this._vm.$axios
      .get("/groups")
      .then(({ data }) => {
        commit("setGroups", data);
      })
      .catch((error) => {
        console.error("vuex: getGroups(): ", error);
        router.push("/groups");
        dispatch(
          "setSnackbarDefault",
          {
            message: "error_load_groups",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingGroups", false);
      });
  },

  async setGroup({ dispatch, commit }, group) {
    commit("setLoadingSelectedGroup", true);
    const { payload, key } = group;
    await this._vm.$axios
      .patch(`/groups/${key}`, payload, {})
      .then(({ data }) => {
        commit("setSelectedGroup", data);
        commit("updateGroupInGroups", data);
        dispatch(
          "setSnackbarDefault",
          {
            message: "success_group_update",
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("vuex: setGroup(): ", error);
        dispatch(
          "setSnackbarDefault",
          {
            message: "error_update_group",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
      });
  },
  async setGroupRule({ commit, dispatch }, group) {
    commit("setLoadingSelectedGroup", true);
    const payload = group.payload;
    const key = group.key;
    await this._vm.$axios
      .put(`/groups/${key}/rules`, payload, {})
      .then((response) => {
        let newRules = response.data;
        commit("updateGroupRules", newRules);
        dispatch(
          "setSnackbarDefault",
          {
            message: "success_group_update",
          },
          { root: true }
        );
      })
      .catch(({ response }) => {
        console.error("vuex: setGroupRule(): ", response);
        dispatch(
          "setSnackbarDefault",
          {
            message: response.data.rules
              ? "starthour_later_than_endhour"
              : "error_update_group",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
      });
  },
  async editGroupMembers({ dispatch, commit }, group) {
    const { payload, key, is_delete } = group;
    commit("setLoadingSelectedGroup", true);
    const URL = `/groups/${key}/users`;
    let promiss_response = [];

    const promisse = is_delete
      ? this._vm.$axios.delete(URL, { data: payload })
      : this._vm.$axios.put(URL, payload, {});

    await promisse
      .then(({ data }) => {
        const { users, group } = data;
        commit("setSelectedGroup", group);
        commit("updateGroupInGroups", group);

        if (!group.custom_response) {
          dispatch(
            "setSnackbarDefault",
            {
              message: "success_group_update",
            },
            { root: true }
          );
        }
        promiss_response = [...users];
      })
      .catch((error) => {
        console.error("vuex: editGroupMembers(): ", error);
        if (!group.custom_response) {
          dispatch(
            "setSnackbarDefault",
            {
              message: "error_update_group",
              color: "error",
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
      });
    return promiss_response;
  },
  async bulkEditGroupMembers({ dispatch, commit }, group) {
    commit("setLoadingSelectedGroup", true);
    const { payload, key, is_delete } = group;
    payload.key = key;
    const URL = "/groups/bulk-users";
    let promiss_response = [];

    const promisse = is_delete
      ? this._vm.$axios.delete(URL, { data: payload })
      : this._vm.$axios.put(URL, payload, {});

    await promisse
      .then(({ data }) => {
        const { groups, user } = data;
        data.userRemove = is_delete;
        commit("updateMultiGroups", groups);
        commit("updateUserInGroups", user);
        if (!group.custom_response) {
          dispatch(
            "setSnackbarDefault",
            {
              message: "success_group_update",
            },
            { root: true }
          );
        }
        promiss_response = data;
      })
      .catch((error) => {
        console.error("vuex: editGroupMembers(): ", error);
        if (!group.custom_response) {
          dispatch(
            "setSnackbarDefault",
            {
              message: "error_update_group",
              color: "error",
            },
            { root: true }
          );
        }
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
      });
    return promiss_response;
  },

  async updateGroupRule({ commit, dispatch }, info) {
    commit("setLoadingSelectedGroup", true);
    const key = info.key;
    const payload = info.payload;
    await this._vm.$axios
      .patch(`/groups/${key}/rules`, payload, {})
      .then((response) => {
        let newRules = response.data;
        commit("updateGroupRules", newRules);
        dispatch(
          "setSnackbarDefault",
          {
            message: "success_rules_update",
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("vuex: updateGroupRule()", error);
        dispatch(
          "setSnackbarDefault",
          {
            message: "error_rules_update",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
      });
  },
  async deleteGroupRule({ commit, dispatch }, info) {
    commit("setLoadingSelectedGroup", true);
    const key = info.key;
    const payload = info.payload;
    await this._vm.$axios
      .delete(`/groups/${key}/rules`, { data: payload })
      .then((response) => {
        let newRules = response.data;
        commit("updateGroupRules", newRules);
        dispatch(
          "setSnackbarDefault",
          {
            message: "success_rules_update",
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("vuex: deleteGroupRule()", error);
        dispatch(
          "setSnackbarDefault",
          {
            message: "error_rules_update",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
      });
  },

  deleteGroup({ commit }, payload) {
    commit("setLoadingSelectedGroup", true);
    return this._vm.$axios
      .delete(`/groups/delete`, { data: payload })
      .then(() => {
        commit("removeGroupInGroups", payload.rule_groups);
      });
  },

  forceGroupsLogout({ commit, dispatch }, keys) {
    const isOneGroup = keys.length;
    commit("setLoadingSelectedGroup", true);
    commit("setLoadingGroups", true);
    return this._vm.$axios
      .patch(`/groups/force-logout:async`, {
        rule_groups: keys,
      })
      .then(() => {
        dispatch(
          "setSnackbarDefault",
          {
            message:
              isOneGroup > 1 ? "logout_groups_success" : "logout_group_success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("vuex: editGroupMembers() ADD: ", error);
        dispatch(
          "setSnackbarDefault",
          {
            message:
              isOneGroup > 1 ? "logout_groups_failure" : "logout_group_failure",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
        commit("setLoadingGroups", false);
      });
  },

  requestChangeGroupsPassword({ commit, dispatch }, keys) {
    commit("setLoadingSelectedGroup", true);
    commit("setLoadingGroups", true);
    return this._vm.$axios
      .patch(`/groups/force-password-change`, {
        rule_groups: keys,
      })
      .then(() => {
        dispatch(
          "setSnackbarDefault",
          {
            message: "force_change_pass_groups_success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("vuex: editGroupMembers() ADD: ", error);
        dispatch(
          "setSnackbarDefault",
          {
            message: "force_change_pass_groups_failure",
            color: "error",
          },
          { root: true }
        );
      })
      .finally(() => {
        commit("setLoadingSelectedGroup", false);
        commit("setLoadingGroups", false);
      });
  },

  cloneGroup({ commit, dispatch }, key) {
    commit("setLoadingSelectedGroup", true);
    return this._vm.$axios.post(`/groups/clone/${key}`).then(({ data }) => {
      dispatch("getUsers", { root: true });
      return data;
    });
  },
};

const handle_obeys_ponto_mais_rules = (data) => {
  data.pontomais_login_rule = !!(
    data.pontomais_login_rule ||
    (data.obeys_ponto_mais_rules && data.advanced_auto_logout)
  );
  data.pontomais_auto_logout_rules =
    data.obeys_ponto_mais_rules && data.advanced_auto_logout
      ? [0]
      : data.pontomais_auto_logout_rules;
  return data;
};

// mutations
const mutations = {
  setSelectedGroup(state, data) {
    data = handle_obeys_ponto_mais_rules(data);
    state.selectedGroup = data;
  },
  setSelectedGroupWithKey(state, key) {
    if (state.groups) {
      let group = state.groups.filter((obj) => obj.key === key)[0];
      if (group != undefined) {
        let data = handle_obeys_ponto_mais_rules(group);
        state.selectedGroup = data;
      } else {
        state.selectedGroup = {};
      }
    }
  },
  setGroups(state, data) {
    data = data.map(handle_obeys_ponto_mais_rules);
    state.groups = data;
  },
  setLoadingGroups(state, status) {
    state.loading.groups = status;
  },
  setLoadingSelectedGroup(state, status) {
    state.loading.selected_group = status;
  },
  setLoadingCreateGroup(state, status) {
    state.loading.create_group = status;
  },
  setEditGroupMode(state, info) {
    if (info.key === "access_permissions" && info.value === true) {
      state.edit.participants = false;
    }
    if (info.key === "participants" && info.value === true) {
      state.edit.access_permissions = false;
    }
    state.edit[info.key] = info.value;
  },
  removeEditMode(state) {
    state.edit.participants = false;
    state.edit.access_permissions = false;
  },
  updateGroupInGroups(state, updatedGroup) {
    for (let index = 0; index < state.groups.length; index++) {
      let element = state.groups[index];
      if (element.key === updatedGroup.key) {
        state.groups[index] = updatedGroup;
      }
    }
    this.commit("setGroups", state.groups);
  },
  updateMultiGroups(state, updatedGroups) {
    const groups = state.groups.map((group) => {
      const foundGroup = updatedGroups.find(
        (groupUpdated) => groupUpdated.key === group.key
      );
      if (foundGroup) {
        return foundGroup;
      }
      return group;
    });
    this.commit("setGroups", groups);
  },
  updateUserInGroups(state, userUpdated) {
    const { email } = userUpdated;

    const groups = state.groups.map((group) => {
      const { users } = group;
      users.map((user) => {
        if (user.email === email) {
          return userUpdated;
        }
        return user;
      });
      return group;
    });
    this.commit("setGroups", groups);
  },
  insertCreatedGroupInGroups(state, createdGroup) {
    state.groups.push(createdGroup);
  },
  updateGroupRules(state, newRules) {
    state.selectedGroup.rules = newRules;
  },
  removeGroupInGroups(state, keys) {
    for (let index = 0; index < state.groups.length; index++) {
      state.groups = state.groups.filter((group) => group.key !== keys[index]);
    }
    this.commit("setGroups", state.groups);
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
