<template>
  <PageLoading v-if="isAuthenticating" />
  <PhoneVerificationFlow v-else-if="isAccessFormLayout" />
  <Activation v-else-if="isActivationSteps" />
  <Dashboard v-else-if="isDashboardLayout" />
  <Default v-else />
</template>
<script>
import Activation from "@/layouts/Activation";
import Dashboard from "@/layouts/Dashboard.vue";
import Default from "@/layouts/Default";

import PhoneVerificationFlow from "@/components/verification/PhoneVerificationFlow.vue";
import PageLoading from "./components/PageLoading.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "App",

  components: {
    Activation,
    PhoneVerificationFlow,
    Dashboard,
    Default,
    PageLoading,
  },
  data() {
    return {
      unwatchIsDashboard: () => {},
    };
  },
  beforeMount() {
    this.unwatchIsDashboard = this.$watch("isDashboardLayout", async () => {
      if (this.isDashboardLayout) {
        this.getScopes();
        this.loadJiraFeedbackIntegration();
        await this.checkControlStatus();
        this.setLoadingSelectedGroup(true);
        this.setLoadingStatus(true);
        this.getCompanySsoStatus();
        this.getCurrentCompany();
        await this.getGroups();
        this.getUsers();
        this.setLoadingStatus(false);
        this.setLoadingSelectedGroup(false);
        this.unwatchIsDashboard();
        if (this.isMicrosoft && this.hasActivePlan)
          await this.getSuiteScopesStatus();
      }
    });
  },
  mounted() {
    if (localStorage.getItem("app_language")) {
      this.$ml.change(localStorage.getItem("app_language"));
      this.setLanguage(localStorage.getItem("app_language"));
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticating",
      "hasFilledForm",
      "isSimulation",
      "isMicrosoft",
      "hasActivePlan",
    ]),

    isAccessFormLayout() {
      return (
        this.$route.meta.requiresAuth &&
        !this.hasFilledForm &&
        !this.isSimulation
      );
    },

    isDashboardLayout() {
      return (
        this.$route.meta.requiresAuth &&
        this.$route.name !== "ActivationSteps" &&
        (this.hasFilledForm || this.isSimulation)
      );
    },

    isActivationSteps() {
      return (
        this.$route.meta.requiresAuth && this.$route.name === "ActivationSteps"
      );
    },
  },
  methods: {
    ...mapActions([
      "getScopes",
      "checkControlStatus",
      "getCompanySsoStatus",
      "getCurrentCompany",
      "getGroups",
      "getUsers",
      "getSuiteScopesStatus",
    ]),
    ...mapMutations([
      "setLanguage",
      "setLoadingStatus",
      "setLoadingSelectedGroup",
    ]),

    loadJiraFeedbackIntegration() {
      var s = document.createElement("script");
      s.src =
        "https://conectanuvem.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=pt-BR&collectorId=12a150ca";
      document.body.appendChild(s);
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
