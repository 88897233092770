<template>
  <v-app>
    <v-overlay
      :value="loading"
      opacity="0.7"
      z-index="200"
      :color="$vuetify.theme.dark ? 'secondary' : 'primary darken-5'"
    >
      <v-progress-circular
        width="6"
        size="100"
        :color="$vuetify.theme.dark ? 'primary' : 'white'"
        indeterminate
      >
        <Logo
          product="conecta_control"
          height="35"
          noMargins
          :white="!$vuetify.theme.dark"
        />
      </v-progress-circular>
    </v-overlay>
    <Navbar @changeSidebarStatus="openOrCloseSidebar" @openChat="initChat" />
    <Sidebar
      :show="showSidebar"
      :showPermanent="permanent"
      :showMini="mini"
      @show="openOrCloseSidebar"
      @showMini="openOrCloseMini"
      :mobile="isMobile"
    />

    <v-main :class="`${$vuetify.theme.dark ? 'background' : ''}`">
      <!-- AVISO DE RESELLER -->
      <v-banner
        v-if="currentUser.is_simulated"
        sticky
        app
        :single-line="!$vuetify.breakpoint.smAndDown"
        :light="!$vuetify.theme.dark"
        :dark="$vuetify.theme.dark"
        color="background"
        :class="`${!$vuetify.breakpoint.smAndDown ? 'py-1' : ''} ${
          mini ? 'px-0' : ''
        } reseller-banner`"
        icon="mdi-alert-circle-outline"
        icon-color="warning"
        style="z-index: 2"
      >
        {{ $ml.get("conecta_partners_simulation") }}
        <span class="font-weight-bold primary--text">{{
          currentUser.domain_name
        }}</span>
        <template v-slot:actions>
          <v-btn
            small
            width="100%"
            outlined
            rounded
            @click="exchangeTokenBack()"
            color="primary"
          >
            <v-icon left v-text="'mdi-arrow-left'" dense />{{
              $ml.get("previous")
            }}
          </v-btn>
        </template>
      </v-banner>

      <v-container fluid class="px-0">
        <v-row class="mx-2 d-flex flex-column mt-1 mb-3" v-if="hasBreadcrumbs">
          <Breadcrumb />
        </v-row>
        <v-container fluid class="px-4 py-0 my-0 mx-0">
          <UpgradeBanner
            v-if="
              !firstSyncUsersStatus.in_sync_process &&
              !loading &&
              !missingScopesLink
            "
          />

          <!-- Botão de voltar para o fluxo de ativação -->
          <v-tooltip
            v-if="
              !isSsoActive &&
              isNotActivationPage &&
              !loading &&
              !hasScheduleActivationInfo &&
              currentActivationStep != 1
            "
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                color="primary"
                large
                :class="`my-4 text-none text-body-2 text-md-body-1 font-weight-bold flex-grow-1 ${pulseBackToActivation}`"
                @click="handleBackToActivationClick"
              >
                <v-icon
                  left
                  size="24"
                  color="primary"
                  v-text="'mdi-arrow-left'"
                />
                {{ $ml.get("back_to_activation") }}
              </v-btn>
            </template>
            <span>{{ $ml.get("disable_sso_warning") }} </span>
          </v-tooltip>

          <!-- ACTIVATION ALERT -->
          <Alert
            class="mx-0 mt-4 mb-4"
            type="info"
            :prominent="false"
            :btnWidth="$vuetify.breakpoint.smAndDown ? '100' : '20'"
            :alignBtnTextStart="$vuetify.breakpoint.smAndDown ? true : false"
            v-if="
              !isSsoActive &&
              hasControlPlan &&
              isNotActivationPage &&
              !loading &&
              !hasScheduleActivationInfo &&
              currentActivationStep <= 1
            "
            dense
            @action="$router.push({ name: 'ActivationSteps' })"
            :actionText="$ml.get('active_control')"
          >
            <span>
              {{ $ml.get("disable_sso_warning") }}
            </span>
          </Alert>

          <!-- SYNC USERS ALERT  -->
          <Alert
            v-if="!!firstSyncUsersStatus.in_sync_process"
            class="mt-4 mb-0"
            :prominent="!$vuetify.breakpoint.mobile"
            color="info"
            outlined
            :showAction="false"
          >
            <h4 class="ml-2">{{ $ml.get("syncUsers") }}:</h4>
            <v-progress-linear
              color="primary"
              class="my-2 ml-2"
              :value="progress"
              height="30"
              rounded
            >
              <strong>
                {{
                  `${$ml.get("synchronizingUsers")}: ${
                    firstSyncUsersStatus.total_users_created
                  } ${$ml.get("from").toLowerCase()} ${
                    firstSyncUsersStatus.total_users
                  }`
                }}
              </strong>
            </v-progress-linear>
          </Alert>

          <!-- SSO Scheduled alert -->
          <Alert
            v-if="
              !isSsoActive &&
              isNotActivationPage &&
              !loading &&
              hasScheduleActivationInfo
            "
            class="mx-0 my-4"
            type="info"
            :prominent="false"
            :btnWidth="$vuetify.breakpoint.smAndDown ? '100' : '20'"
            :alignBtnTextStart="$vuetify.breakpoint.smAndDown ? true : false"
            dense
            @action="showCancelScheduleActivationDialog = true"
            :actionText="$ml.get('cancel_schedule_activation')"
          >
            <span>
              {{ $ml.get("schedule_activation_alert_description") }}
              {{
                formatDateAndHour(scheduleActivationInfo.activation_datetime)
              }}
            </span>
          </Alert>

          <!-- SCOPES ALERT  -->
          <Alert
            v-for="(item, key) in scopesAlerts"
            :key="key"
            class="my-1"
            :show-action="false"
            :type="item.type"
            outlined
            :prominent="!$vuetify.breakpoint.mobile"
            :text-color="`${item.type}--text text--darken-4`"
            dense
          >
            <h4 class="mb-1">
              <strong>{{ $ml.get(item.title) }}!</strong>
            </h4>
            <span class="text-body-2" v-html="$ml.get(item.subtitle)"></span>
            <v-divider class="my-1 info" style="opacity: 0.22"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters align="center">
              <v-col lg="9" sm="6" xs="12" md="8" class="pa-0 grow">
                <span
                  class="text-body-2"
                  v-html="$ml.get(item.description)"
                ></span>
              </v-col>
              <v-col
                xs="12"
                sm="6"
                lg="3"
                md="4"
                class="mt-2 shrink d-flex justify-end"
              >
                <v-btn
                  v-if="item.showSecondaryButton"
                  text
                  small
                  :color="item.type"
                  :href="linkDocAboutScopes"
                  target="_blank"
                  class="mr-2 text-body-2 font-weight-medium"
                >
                  {{ $ml.get("more_link_text") }}
                </v-btn>
                <v-btn
                  v-if="item.showPrimaryButton"
                  text
                  small
                  outlined
                  :color="item.type"
                  :href="item.redirectLink"
                  target="_blank"
                  class="ml-auto text-body-2 font-weight-medium"
                  :style="`border: 1px ${item.color} solid`"
                >
                  {{ $ml.get(item.redirectText) }}
                </v-btn>
              </v-col>
            </v-row>
          </Alert>
        </v-container>
        <router-view />
      </v-container>

      <!-- SNACKBARS DE AVISO -->
      <v-snackbar
        v-if="snackbarMessageKey !== ''"
        v-model="showGlobalSnackbar"
        multi-line
        :color="snackbarColor"
        elevation="2"
        :timeout="snackbarTimeout"
      >
        <div v-if="snackbarIsAKey" class="font-weight-medium">
          {{ $ml.get(snackbarMessageKey) }}
        </div>
        <div v-else class="font-weight-medium">
          {{ snackbarMessageKey }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="resetSnackbarInfo()">
            {{ $ml.get("close") }}
          </v-btn>
        </template>
      </v-snackbar>

      <ScheduleMeeting
        v-if="!loading && !isSmallClient && isIntrialAfterFourDays"
      />

      <ConfirmationDialog
        :target="$ml.get('cancel_schedule_activation_dialog_title')"
        :show="showCancelScheduleActivationDialog"
        @close="showCancelScheduleActivationDialog = false"
        @ok="cancelScheduleActivation"
        :reversible="true"
        :loading="cancelActivationLoading"
      />

      <HeaderDialog
        :show="attentionToPriceChange"
        id="new_product_price_editing"
        :title="$ml.get('priceAdjustment')"
        :subtitle="$ml.get('priceChangeSubtitle')"
        width="800"
        :showCloseBtn="false"
        :actionText="$ml.get('iUnderstood')"
        @action="priceChangeNoticed"
      >
        <template v-slot:body>
          <v-row class="mx-3 mb-2" no-gutters>
            <p class="mt-2">
              <span v-html="$ml.get('priceChangeWarning')"></span>
              <a
                href="https://conectasuite.com/precos/"
                target="_blank"
                class="text-body-1 font-weight-bold text-decoration-none"
              >
                <span class="blue--text text--darken-2"> Conecta Suite.</span>
              </a>
            </p>

            <p>{{ $ml.get("noActionNeeded") }}</p>

            <p>
              {{ $ml.get("willingToHelp") }}
              <a
                class="text-body-1 font-weight-bold text-decoration-none"
                href="mailto:comercial@conectasuite.com"
                ><span class="text-body-1 font-weight-bold">
                  comercial@conectasuite.com</span
                ></a
              >.
            </p>

            <p>{{ $ml.get("thanks4BeeingClient") }}</p>
          </v-row>
        </template>
      </HeaderDialog>

      <v-btn
        v-show="chatLoading"
        color="primary"
        elevation="2"
        fab
        loading
        class="fab-button-chat"
      ></v-btn>
    </v-main>
  </v-app>
</template>
<script>
import Alert from "@/components/base/Alert.vue";
import Breadcrumb from "@/components/base/Breadcrumb";
import ScheduleMeeting from "@/components/base/ScheduleMeeting";
import UpgradeBanner from "@/components/billing/upgrade/UpgradeBanner.vue";
import Navbar from "@/components/main/Navbar.vue";
import Sidebar from "@/components/main/Sidebar.vue";
import {
  googleAppsPainelLink,
  googleScopesDocLink,
  helpMoreLink,
  linkAdminGoogleApps,
  microsoftAppsPainelLink,
  microsoftScopesDocLink,
  suportEmail,
} from "@/helpers/links";
import { formatDateAndHour } from "@/helpers/utils";
import {
  SUITE_DEV_PROJECT_NAME,
  SUITE_PROD_PROJECT_NAME,
} from "@/helpers/variables";
import { getDatabase, ref, set } from "firebase/database";
import * as moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { domainsToSeePriceChangeWarning } from "@/helpers/domainsSeePriceChange";

export default {
  components: {
    Navbar,
    Sidebar,
    Breadcrumb,
    Alert,
    ScheduleMeeting,
    UpgradeBanner,
  },
  data() {
    return {
      showSidebar: true,
      mini: false,
      permanent: true,
      helpMoreLink,
      linkAdminGoogleApps,
      suportEmail,
      showCancelScheduleActivationDialog: false,
      cancelActivationLoading: false,
      attentionToPriceChange: false,
      domainsToSeePriceChangeWarning,
    };
  },

  computed: {
    ...mapGetters([
      "loading",
      "snackbar",
      "showSnackbar",
      "snackbarMessageKey",
      "snackbarTimeout",
      "snackbarColor",
      "token",
      "currentLanguageCode",
      "snackbarIsAKey",
      "isSsoActive",
      "controlPlan",
      "hasControlPlan",
      "currentUser",
      "missingScopesControl",
      "companyInSuite",
      "hasScheduleActivationInfo",
      "scheduleActivationInfo",
      "isSmallClient",
      "hasAdminPermission",
      "mainDomain",
      "controlInTrial",
      "firstSyncUsersStatus",
      "pulseBackToActivation",
      "currentActivationStep",
      "isMicrosoft",
      "hasUnacceptedSuiteScopes",
      "hasActivePlan",
      "chatLoading",
    ]),

    progress() {
      const { total_users, total_users_created } = this.firstSyncUsersStatus;
      return (total_users_created / total_users) * 100;
    },

    showGlobalSnackbar: {
      get() {
        return this.showSnackbar;
      },
      set(new_value) {
        this.showOrHideSnackbar(new_value);
      },
    },

    hasBreadcrumbs() {
      return this.$route.meta.breadcrumb ? true : false;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    isIntrialAfterFourDays() {
      if (this.controlInTrial) {
        const endTrialDate = moment(this.controlPlan.end_trial);
        const today = moment().startOf("day");
        const differenceInDays = endTrialDate.diff(today, "days");

        const totalDaysInTrial = 14;

        if (totalDaysInTrial - differenceInDays >= 4) return true;
      }

      return false;
    },

    isNotActivationPage() {
      return this.$route.name !== "ActivationSteps";
    },

    scopesAlerts() {
      return [
        {
          show: !this.missingScopesControl,
          showPrimaryButton: this.hasAdminPermission,
          showSecondaryButton: true,
          title: "attention",
          subtitle: "error_missing_scope",
          description: "error_missing_scope_alert",
          redirectText: "allow_access",
          redirectLink: this.missingScopesLink,
          type: "warning",
          color: "#f39c12",
        },
        {
          show:
            this.isMicrosoft &&
            this.hasUnacceptedSuiteScopes &&
            this.missingScopesControl &&
            this.hasActivePlan,
          showPrimaryButton: true,
          showSecondaryButton: false,
          title: "update_available",
          subtitle: "error_suite_missing_scope",
          description: "error_suite_missing_scope_alert",
          redirectText: "go_to_suite",
          redirectLink: process.env.VUE_APP_SUITE_BASE_URL,
          type: "info",
          color: "#2196f3",
        },
      ].filter((item) => item.show);
    },

    missingScopesLink() {
      return this.isMicrosoft ? microsoftAppsPainelLink : googleAppsPainelLink;
    },

    linkDocAboutScopes() {
      return this.isMicrosoft ? microsoftScopesDocLink : googleScopesDocLink;
    },

    feedbacksButtons() {
      return document.getElementsByClassName("feedback-button");
    },
  },

  watch: {
    "$route.path"() {
      this.removeEditMode();
      this.resetSnackbarInfo();
    },

    "$route.name": {
      handler() {
        this.permanent = false;
        this.showSidebar = false;
        if (!this.isMobile) {
          this.permanent = true;
        }
      },
      immediate: true,
    },

    isMobile() {
      if (this.isMobile) {
        this.mini = false;
        this.showSidebar = false;
      }
    },

    currentLanguageCode() {
      const currentLanguage = {
        en: "en",
        pt_BR: "pt",
        es: "es",
      };
      this.$vuetify.lang.current = currentLanguage[this.currentLanguageCode];
    },

    showGlobalSnackbar() {
      if (!this.showGlobalSnackbar) {
        this.resetSnackbarInfo();
      }
    },

    feedbacksButtons(newValue, oldValue) {
      if (newValue.lenght !== oldValue.lenght) {
        this.removeFeedback();
        this.setFeedback();
      }
    },
  },

  methods: {
    ...mapActions([
      "resetSnackbarInfo",
      "exchangeToken",
      "setSnackbarDefault",
      "initSupportChat",
    ]),

    ...mapMutations([
      "showOrHideSnackbar",
      "removeEditMode",
      "setScheduleActivationInfo",
      "setPulseBackToActivation",
    ]),

    formatDateAndHour,

    async initChat() {
      await this.initSupportChat();
    },

    priceChangeNoticed() {
      this.setUserNoticedPriceChange();
      this.attentionToPriceChange = false;

      this.saveNoticedPriceChange();
    },

    setUserNoticedPriceChange() {
      const database = getDatabase();

      set(ref(database, `noticed_price_change/${this.currentUser.key}`), {
        email: this.currentUser.email,
        date: moment().utc().locale("pt-br").format("DD/MM/YYYY HH:mm:ss"),
        id_google: this.currentUser.user_id,
      });
    },

    saveNoticedPriceChange() {
      const url = `${process.env.VUE_APP_BIG_QUERY_API}/save-noticed-price-changes`;
      const project_name =
        process.env.NODE_ENV !== "production"
          ? SUITE_DEV_PROJECT_NAME
          : SUITE_PROD_PROJECT_NAME;

      const { name, user_id, email } = this.currentUser;

      const payload = {
        project_name,
        app: "Conecta Control",
        name,
        id_google: user_id,
        email,
        main_domain: this.mainDomain,
      };

      this.$axios
        .post(url, payload)
        .then(() => {})
        .catch((err) => {
          console.error("saveNoticedPriceChange()", err);
        })
        .finally(() => {});
    },

    exchangeTokenBack() {
      this.exchangeToken().then(() => {
        if (this.$router.currentRoute.name != "Home") {
          this.$router.push({ name: "Home" });
        }
        this.$router.go();
      });
    },

    openOrCloseSidebar(show) {
      if (this.isMobile) {
        this.mini = false;
        this.permanent = false;
        this.showSidebar =
          show && show.constructor === Boolean ? show : !this.showSidebar;
      } else {
        this.showSidebar = true;
        this.mini =
          show !== undefined && show !== null && show.constructor === Boolean
            ? !show
            : !this.mini;
      }
    },

    openOrCloseMini(mini) {
      if (!this.isMobile) {
        this.showSidebar = true;
        this.mini =
          mini !== undefined && mini !== null && mini.constructor === Boolean
            ? mini
            : !this.mini;
      } else {
        this.mini = false;
        this.permanent = false;
      }
    },

    setFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function (showCollectorDialog) {
          _this.feedbacksButtons.forEach((element) => {
            element.addEventListener("click", (e) => {
              e.preventDefault();
              showCollectorDialog();
            });
          });
        },
      };
    },

    removeFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function () {
          _this.feedbacksButtons.forEach((element) => {
            element.removeEventListener("click", false);
          });
        },
      };
    },

    cancelScheduleActivation() {
      this.cancelActivationLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/sso/cancel-activation`;

      this.$axios
        .post(
          url,
          {},
          {
            headers: { Authorization: "Bearer " + this.token },
          }
        )
        .then(() => {
          this.setSnackbarDefault({
            message: "success_cancel_schedule_activation",
            color: "success",
            show: true,
          });

          this.setScheduleActivationInfo({});

          if (this.$route.name === "SuccessfulActivation") {
            this.$router.push({ name: "Home" });
          }
        })
        .catch(() => {
          this.setSnackbarDefault({
            message: "error_cancel_activation_async",
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.showCancelScheduleActivationDialog = false;
          this.cancelActivationLoading = false;
        });
    },

    handleBackToActivationClick() {
      if (this.pulseBackToActivation) {
        this.setPulseBackToActivation();
      }
      this.$router.push({ name: "ActivationSteps" });
    },
  },

  async created() {
    if (localStorage.getItem("darkMode")) {
      this.$vuetify.theme.dark = JSON.parse(localStorage.getItem("darkMode"));
    }
  },

  mounted() {
    this.setFeedback();
  },

  beforeDestroy() {
    this.removeFeedback();
  },
};
</script>

<style lang="scss" scoped>
.reseller--alert-position {
  z-index: 5;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .reseller--alert-position > span {
    display: block;
  }
}
</style>
